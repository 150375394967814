import React, {useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import {Icon, Button, Select} from 'semantic-ui-react';

import {translations} from 'resources';
import {LanguageContext, UserContext, BotContext} from 'context';
import ayyildiz_logo from 'resources/images/ayyildiz_logo.png';
import styles from './top-bar.module.css';

const languageOptions = [
  {key: 'Türkçe', value: 'tr', text: 'Türkçe'},
  {key: 'English', value: 'en', text: 'English'},
  {key: 'العربية', value: 'arb', text: 'العربية'},
];

export default function TopBar({startBtn}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: {user},
    resetUser,
  } = useContext(UserContext);

  const {resetBots} = useContext(BotContext);

  const {topbar_container, logo, menu_container, list_container, list_item, icon_container} = styles;

  const renderMenu = () => {
    return (
      <div className={menu_container}>
        <div className={icon_container} onClick={() => setIsMenuVisible(false)}>
          <Icon className={'big'} color="red" name="close" />
        </div>

        <div className={list_container}>
          <div className={list_item} onClick={() => setIsMenuVisible(false)}>
            <Link to="/panel">{translations[appLanguage]['g61']}</Link>
          </div>
          {(user.isModerator || user.isMaster) && (
            <div className={list_item} onClick={() => setIsMenuVisible(false)}>
              <Link to="/moderator">{translations[appLanguage]['g131']}</Link>
            </div>
          )}
          <div className={list_item} onClick={() => setIsMenuVisible(false)}>
            <Link to="/profileEdit">{translations[appLanguage]['g94']}</Link>
          </div>
          <div className={list_item} onClick={() => setIsMenuVisible(false)}>
            <Link to="/payment">{translations[appLanguage]['g130']}</Link>
          </div>
          <div className={list_item} onClick={() => setIsMenuVisible(false)}>
            <Select
              value={appLanguage}
              onChange={(e, {value}) => changeAppLanguage(value)}
              className={styles.select}
              placeholder={translations[appLanguage]['g149']}
              options={languageOptions}
            />
          </div>
          <div
            className={list_item}
            onClick={() => {
              setIsMenuVisible(false);
              localStorage.setItem('token', '');
              resetUser();
              resetBots();
            }}
          >
            <Link to="/signin">{translations[appLanguage]['g129']}</Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={topbar_container}>
        <Link to="/panel">
          <img className={logo} src={ayyildiz_logo} alt="ayyildiz_logo" />
        </Link>
        {startBtn}
        <Button onClick={() => setIsMenuVisible(true)} icon color="black" basic>
          <Icon name="bars" />
        </Button>
      </div>
      {isMenuVisible && renderMenu()}
    </>
  );
}
