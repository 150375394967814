import React, {useContext, useState, useEffect} from 'react';
import {Menu, Layout, Button, Input, Tooltip} from 'antd';
import ScrollToBottom from 'react-scroll-to-bottom';
import {Checkbox} from 'semantic-ui-react';

import {translations} from 'resources';
import {LanguageContext, MessageContext, BotContext} from 'context';
import styles from './messages.module.css';
import {MenuUnfoldOutlined, MenuFoldOutlined, SendOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';

import backgroundImage from 'resources/images/chat-bg.jpg';

const {Header, Sider, Content} = Layout;

export default function Messages() {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedIgg, setSelectedIgg] = useState('');
  const [selectedGameNick, setSelectedGameNick] = useState('');
  const [selectedClan, setSelectedClan] = useState('');
  const [message, setMessage] = useState('');
  const [messageEnabled, setMessageEnabled] = useState(false);
  const [lastSended, setLastSended] = useState(0);
  const [warningTime, setWarningTime] = useState(0);
  const [forbiddenMessage, setForbiddenMessage] = useState(false);
  // const [scrollElement, setScrollElement] = useState();
  const [messages, setMessages] = useState([]);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {players, chats},
    getPlayers,
    getMessages,
    sendMessage,
    setSelectedIggId,
  } = useContext(MessageContext);
  const {
    state: {selectedIndex, bots},
    pushBot,
    setBot,
  } = useContext(BotContext);
  const bot = bots?.[selectedIndex];

  const {records_tab_container, clear_btn} = styles;

  useEffect(() => {
    if (bot) {
      getPlayers(bot._id);
      setMessageEnabled(!!bot.messageEnabled);
    }
    setMessages([]);
    setSelectedGameNick('');
    setSelectedClan('');
    setSelectedIgg('');
  }, [bot, bots]);

  useEffect(() => {
    console.log('chats', chats);
    if (selectedIgg && chats?.[selectedIgg]) setMessages([...chats?.[selectedIgg]]);
    // setTimeout(() => {
    //   scrollMessage();
    //   console.log('wwwwwwwwwwww');
    // }, 3000);
  }, [selectedIgg, chats]);

  useEffect(() => {
    if (bot && selectedIgg) {
      setSelectedIggId(selectedIgg);
      getMessages(bot._id, selectedIgg);
    }
    // setTimeout(() => {
    //   scrollMessage();
    //   console.log('wwwwwwwwwwww');
    // }, 3000);
  }, [selectedIgg, bot]);

  useEffect(() => {
    // scrollMessage();
  }, [messages]);

  useEffect(() => {
    if (!selectedIgg && players?.length > 0) {
      setSelectedIgg(players[0].iggId);
      setSelectedGameNick(players[0].gameNick);
      setSelectedClan(players[0].clan || '');
    }
  }, [players]);

  useEffect(() => {
    return () => setSelectedIggId('');
  }, []);

  const renderPlayers = () => {
    return players.map((el, i) => (
      <Menu.Item
        onClick={() => {
          setCollapsed(true);
          setSelectedIgg(el.iggId);
          setSelectedGameNick(el.gameNick);
          setSelectedClan(el.clan || '');
        }}
        key={'' + i}
      >
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>{el.gameNick}</div>
          <div>{el.unread}</div>{' '}
        </div>
      </Menu.Item>
    ));
  };

  // const scrollMessage = () => {
  //   if (scrollElement) scrollElement.scrollIntoView({behavior: 'smooth'});
  // };

  const renderMessages = () => {
    const returnThis = messages.map((item, i) => {
      if (item.inOut == 0)
        return (
          <div key={i} className={styles.messageContainerRight}>
            <div className={styles.messageRight}>
              <div>{item.message}</div>
              <div className={styles.messageDate}>{new Date(item.date).toLocaleTimeString()}</div>
            </div>
          </div>
        );
      else
        return (
          <div key={i} className={styles.messageContainerLeft}>
            <div className={styles.messageLeft}>
              <div>{item.message}</div>
              <div className={styles.messageDate}>{new Date(item.date).toLocaleTimeString()}</div>
            </div>
          </div>
        );
    });
    // returnThis.push(<div style={{float: 'left', clear: 'both'}} ref={(el) => setScrollElement(el)}></div>);
    return returnThis;
  };

  return (
    <div className={records_tab_container}>
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={messageEnabled}
          label={translations[appLanguage]['g190']}
          onChange={(e, {checked}) => {
            setMessageEnabled(checked);
            pushBot({
              _id: bot?._id,
              messageEnabled: checked,
            });
          }}
          className={styles.main_select}
        />
      </div>
      {messageEnabled && (
        <div>
          <Layout
            style={{
              overflow: 'auto',
              maxHeight: '450px',
              maxWidth: '110px !important',
              minWidth: '5px !important',
              left: 0,
            }}
          >
            {
              <Sider
                hidden={collapsed}
                style={{overflow: 'auto', height: '450px', left: 0, background: 'gray'}}
                trigger={null}
              >
                <Menu
                  style={{background: 'gray', maxWidth: '110px !important', minWidth: '5px !important'}}
                  theme="dark"
                  mode="inline"
                  defaultSelectedKeys={['0']}
                >
                  {renderPlayers()}
                </Menu>
              </Sider>
            }

            <Layout className="site-layout">
              <Header
                className="site-layout-background"
                style={{padding: 0, background: 'white', height: '45px', display: 'flex', alignItems: 'center'}}
              >
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  style: {
                    fontSize: '14px',
                    lineHeight: '40px',
                    padding: ' 0 24px',
                    cursor: 'pointer',
                    transition: 'color 0.3s',
                  },
                  onClick: () => setCollapsed(!collapsed),
                })}
                <div style={{margin: '3px'}}>
                  {selectedIgg
                    ? selectedGameNick + (messages?.[0]?.clan ? ' (' + messages?.[0]?.clan + ')' : '')
                    : translations[appLanguage]['g178']}
                </div>
              </Header>
              {collapsed && (
                <Content
                  className="site-layout-background"
                  style={{
                    margin: '24px 16px',
                    minHeight: '280px',
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    background: 'rgb(237, 237, 237)',
                  }}
                >
                  {/* <div className={styles.messagesContainer}>{renderMessages()}</div> */}
                  <ScrollToBottom className={styles.messagesContainer}>{renderMessages()}</ScrollToBottom>
                  <div style={{border: 'solid 1px blue', display: 'flex', marginTop: '10px'}}>
                    <Input
                      disabled={!selectedIgg?.length}
                      value={message}
                      onKeyPress={(e) => {
                        if ((e.charCode === 13 || e.keyCode === 13) && message?.length) {
                          if (Date.now() - lastSended < 4000) return setWarningTime(Date.now());
                          setWarningTime(0);
                          setLastSended(Date.now());
                          sendMessage(bot?._id, selectedIgg, message);
                          setMessage('');
                        }
                      }}
                      onChange={(e) => {
                        if (Date.now() - lastSended > 4000) setWarningTime(0);
                        setMessage((e.target.value || '').substring(0, 250));
                        if (e.target.value?.indexOf?.('[chat_face') >= 0) setForbiddenMessage(true);
                        else setForbiddenMessage(false);
                      }}
                    />

                    {/* <Tooltip title="Send"> */}
                    <Button
                      disabled={!message?.length || forbiddenMessage}
                      onClick={() => {
                        if (Date.now() - lastSended < 4000) return setWarningTime(Date.now());
                        setWarningTime(0);
                        setLastSended(Date.now());
                        sendMessage(bot?._id, selectedIgg, message);
                        setMessage('');
                      }}
                      shape="circle"
                      icon={<SendOutlined />}
                    />
                    {/* </Tooltip> */}
                  </div>
                
                  {warningTime ? (
                    <div style={{color: 'red', fontSize: '12px'}}>
                      {translations[appLanguage]['g180'] +
                        Math.ceil((4000 - (warningTime - lastSended)) / 1000) +
                        translations[appLanguage]['g181']}
                    </div>
                  ) : (
                    ''
                  )}
                </Content>
              )}
            </Layout>
          </Layout>
        </div>
      )}{' '}
    </div>
  );
}
