import {SET_ERROR, SET_LOADING} from 'context/shared';
import {apiURL, axiosClient} from 'service';
import {SET_MESSAGES, SET_PLAYERS, ADD_MESSAGES, SET_SELECTED_IGG_ID} from './keys';

export const getPlayers = (dispatch) => async (_id) => {
  dispatch({type: SET_LOADING});
  const {data} = await axiosClient.get(`${apiURL.chatPlayers}?_id=${_id}`, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });
  if (Array.isArray(data)) {
    dispatch({type: SET_PLAYERS, payload: data});
  }
};

export const setPlayers = (dispatch) => async (players) => {
  if (Array.isArray(players)) {
    dispatch({type: SET_PLAYERS, payload: players});
  }
};

export const getMessages = (dispatch) => async (_id, iggId) => {
  dispatch({type: SET_LOADING});
  const {data} = await axiosClient.get(`${apiURL.chatMessages}?_id=${_id}&iggId=${iggId}`, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });
  if (Array.isArray(data)) {
    console.log('messagedata', data);
    dispatch({type: SET_MESSAGES, payload: {data, iggId}});
  }
};

export const setMessages = (dispatch) => async (messages, iggId) => {
  if (Array.isArray(messages)) {
    dispatch({type: SET_MESSAGES, payload: {data: messages, iggId}});
  }
};

//TODO: send only changed parts
export const sendMessage = (dispatch) => async (_id, iggId, message) => {
  dispatch({type: ADD_MESSAGES, payload: {iggId, data: {message, inOut: 0, date: Date.now()}}});
  const {data} = await axiosClient.post(
    apiURL.chatSend,
    {botId: _id, iggId, message},
    {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    }
  );
};

export const setSelectedIggId = (dispatch) => async (iggId) => {
  dispatch({type: SET_SELECTED_IGG_ID, payload: iggId});
};
