import {CHANGE_APP_LANGUAGE, INITIALIZE_STATE_SUCCESS} from 'context/language/keys';

export const initialState = {
  appLanguage: localStorage.getItem('appLanguage') || 'en',
  languageSetted: localStorage.getItem('languageSetted'),
};

export default (state, {type, payload}) => {
  switch (type) {
    case CHANGE_APP_LANGUAGE:
      return {...state, appLanguage: payload, languageSetted: true};
    default:
      return state;
  }
};
