import React, {useState, useContext, useEffect} from 'react';
import {Checkbox, Button, Dropdown, Form, TextArea, Tab} from 'semantic-ui-react';
import DatePicker, {registerLocale} from 'react-datepicker';

import {translations} from 'resources';
import {LanguageContext, BotContext, UserContext} from 'context';
import styles from './botEdit.module.css';
import {apiURL, axiosClient} from 'service';
import {parsePageSettings} from '../../../helpers/page';
import PageHeader from '../../components/pageHeader';

const packageTypes = {
  ac: 0,
  standart: 1,
  askeriCagri: 2,
  farm: 3,
  shield: 4,
};

const kingdomOptions = () => {
  let returnThis = [];
  for (let a = 1; a < 200; a++) returnThis.push({key: '' + a, value: a, text: '' + a});
  return returnThis;
};

export default function Connection(props) {
  const [smallScreen, setSmallScreen] = useState(false);
  const [localBot, setLocalBot] = useState({});
  const [owner, setOwner] = useState('');
  const [botId, setBotId] = useState('');
  const [passWord, setPassWord] = useState('');
  const [server, setServer] = useState('');
  const [email, setEmail] = useState('');
  const [gameNick, setGameNick] = useState('');
  const [iggID, setIggID] = useState('');
  const [note, setNote] = useState('');
  const [isGmail, setIsGmail] = useState(false);
  const [idBuffer, setIdBuffer] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [botType, setBotType] = useState(0);
  const [wrongPassWord, setWrongPassWord] = useState(false);
  // const [idBufferReady, setIdBufferReady] = useState(false);
  const [paymentNeeded, setPaymentNeeded] = useState(false);
  const [dontClose, setDontClose] = useState(false);
  const [isFarm, setIsFarm] = useState(false);
  const [userStarted, setUserStarted] = useState(false);
  const [changed, setChanged] = useState(false);
  const [finishDate, setFinishDate] = useState(new Date());
  const [tab, setTab] = useState(0);
  const [paymentList, setPaymentList] = useState([]);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {user},
    getUser,
  } = useContext(UserContext);
  const {
    state: {selectedIndex, bots},
    pushBot,
  } = useContext(BotContext);
  const {
    connection_tab_container,
    input_container,
    buttons_container,
    connection_input,
    connection_text,
    checkBox_container,
  } = styles;

  const onResize = () => {
    if (window.innerWidth < 800) {
      setSmallScreen(true);
    } else setSmallScreen(false);
  };

  const getPayments = async (_id) => {
    const {data} = await axiosClient.get(apiURL.paymentsOfBot + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data) setPaymentList(data);
  };

  const getBot = async (_id) => {
    const {data} = await axiosClient.get(apiURL.botFull + '?_id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    setLocalBot(data);

    setOwner(data.ownerId);
    setBotId(data._id);
    setPassWord(data.passWord);
    setServer(data.server);
    setGameNick(data.gameNick);
    setEmail(data.email);
    setIggID(data.iggID);
    setFinishDate(data.finishDate);
    setNote(data.note);
    setIsGmail(data.isGmail);
    setIdBuffer(data.idBuffer);
    setIsActive(data.isActive);
    setBotType(data.botType || 0);
    setWrongPassWord(data.wrongPassWord);
    // setIdBufferReady(data.idBufferReady);
    setPaymentNeeded(data.paymentNeeded);
    setDontClose(data.dontClose);
    setIsFarm(data.isFarm);
    setUserStarted(data.userStarted);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
  }, []);

  useEffect(() => {
    const pageSetting = parsePageSettings(props.match.params.id);
    if (pageSetting) {
      getBot(pageSetting.id);
      getPayments(pageSetting.id);
    }
    getUser();
  }, [props.match.params.id]);

  const renderPass = () => {
    //if (passWord)
    return (
      <div className={styles.input_container}>
        <div className={styles.connection_text}>{translations[appLanguage]['g34']} </div>
        <div className={'input small ui ' + styles.connection_input}>
          <input
            type="text"
            disabled={!localBot}
            value={passWord}
            onChange={({target: {value}}) => {
              setPassWord(value);
              setChanged(true);
            }}
          />
        </div>
      </div>
    );
  };

  const renderSave = () => {
    if (changed)
      return (
        <div>
          <Button
            primary
            onClick={() => {
              pushBot({
                _id: localBot._id,
                passWord,
                server,
                email,
                gameNick,
                iggID,
                finishDate,
                note,
                isGmail,
                idBuffer,
                isActive,
                wrongPassWord,
                // idBufferReady,
                paymentNeeded,
                dontClose,
                isFarm,
                botType,
              });
              setChanged(false);
            }}
          >
            {translations[appLanguage]['g76']}
          </Button>
        </div>
      );
  };

  const renderStart = () => {
    return (
      <div>
        <Button
          primary
          onClick={() => {
            pushBot({
              _id: localBot._id,
              userStarted: !userStarted,
            });
            setUserStarted(!userStarted);
            setChanged(false);
          }}
        >
          {userStarted ? 'Botu Durdur' : 'Botu Başlat'}
        </Button>
      </div>
    );
  };

  const onEditClick = (payment) => {
    let pageSettings = {botId, paymentId: payment._id};
    props.history.push('/moderator/payment' + JSON.stringify(pageSettings));
  };

  const onNewPaymentClick = () => {
    let pageSettings = {botId};
    props.history.push('/moderator/payment' + JSON.stringify(pageSettings));
  };

  const renderPaymentsHead = () => {
    if (!smallScreen) {
      return (
        <thead>
          <tr>
            <th colSpan="1">{translations[appLanguage]['g40']}</th>
            <th colSpan="1">{translations[appLanguage]['g111']}</th>
            <th colSpan="1">{translations[appLanguage]['g139']}</th>
            <th colSpan="1">{translations[appLanguage]['g138']}</th>
            <th colSpan="1">{translations[appLanguage]['g133']}</th>
            <th colSpan="1">{'ID'}</th>
            <th colSpan="1">{}</th>
          </tr>
        </thead>
      );
    }
  };

  const renderPaymentsList = () => {
    return paymentList?.map((payment) => {
      return (
        <tr key={payment._id}>
          <td style={{textTransform: 'capitalize'}}>
            {(smallScreen ? translations[appLanguage]['g40'] + ' ' : '') +
              (new Date(payment.date).toLocaleString() || '--')}
          </td>
          <td style={{textTransform: 'capitalize'}}>
            {(smallScreen ? translations[appLanguage]['g111'] + ' ' : '') + payment.amount}
          </td>
          <td>
            {(smallScreen ? translations[appLanguage]['g139'] + ' ' : '') +
              Object.keys(packageTypes)[payment.packetType || 0]}
          </td>
          <td>{(smallScreen ? translations[appLanguage]['g138'] + ' ' : '') + (payment.days || '--')}</td>
          <td>{(smallScreen ? translations[appLanguage]['g133'] + ' ' : '') + (payment.note || '')}</td>
          <td>{(smallScreen ? 'ID ' : '') + (payment.shortid || '')}</td>
          {user.isMaster && (
            <td className={styles.iconLabelContainer}>
              <div className={styles.iconContainer}>
                <div>
                  <button
                    className="ui button icon blue"
                    icon
                    onClick={() => {
                      onEditClick(payment);
                    }}
                  >
                    <i style={{color: 'white'}} className="pencil alternate icon large" />
                  </button>
                </div>
              </div>
            </td>
          )}
        </tr>
      );
    });
  };

  const panes = [
    {
      menuItem: translations[appLanguage]['g10'],
      render: () => (
        <Tab.Pane>
          {' '}
          {/* <div className={connection_tab_container}> */}
          <div className={input_container}>
            <div className={connection_text}>{translations[appLanguage]['g88'] + ' :'}</div>
            <DatePicker
              locale="tr"
              dateFormat="dd/MM/yyyy"
              className="fluid"
              disabled={!user.isMaster}
              selected={new Date(finishDate)}
              onChange={(date) => {
                setFinishDate(date);
                setChanged(true);
              }}
            />
            {user?.isMaster && (
              <div>
                <Button
                  style={{marginLeft: '5px'}}
                  onClick={() => {
                    setFinishDate(new Date(new Date(finishDate).getTime() + 30 * 24 * 60 * 60 * 1000));
                  }}
                >
                  +1
                </Button>
              </div>
            )}
          </div>
          <div className={input_container}>
            <div className={connection_text}>{translations[appLanguage]['g33'] + ' :'}</div>
            <Dropdown
              value={server}
              onChange={(e, {value}) => {
                setServer(value);
                setChanged(true);
              }}
              search
              clearable
              selection
              className={connection_input}
              placeholder="Kingdom"
              options={kingdomOptions()}
            />
          </div>
          <div className={styles.input_container}>
            <div className={styles.connection_text}>{translations[appLanguage]['g3']} </div>
            <div className={'input small ui ' + styles.connection_input}>
              <input
                type="text"
                disabled={!localBot}
                value={email}
                onChange={({target: {value}}) => {
                  setEmail(value);
                  setChanged(true);
                }}
              />
            </div>
          </div>
          <div className={styles.input_container}>
            <div className={styles.connection_text}>{translations[appLanguage]['g85']} </div>
            <div className={'input small ui ' + styles.connection_input}>
              <input
                disabled={!localBot}
                value={gameNick}
                onChange={({target: {value}}) => {
                  setGameNick(value);
                  setChanged(true);
                }}
              />
            </div>
          </div>
          <div className={styles.input_container}>
            <div className={styles.connection_text}>IGG ID </div>
            <div className={'input small ui ' + styles.connection_input}>
              <input
                disabled={!localBot}
                value={iggID}
                onChange={({target: {value}}) => {
                  setIggID(value);
                  setChanged(true);
                }}
              />
            </div>
          </div>
          {renderPass()}
          {user?.isMaster && (
            <div className={input_container}>
              <div className={connection_text}>{'Server IP:'}</div>

              <div className={'input small ui ' + styles.connection_input}>
                <input disabled={!localBot} value={localBot?.serverIp} />
              </div>
            </div>
          )}
          {user?.isMaster && (
            <div className={styles.input_container}>
              <div className={styles.connection_text}>IDbuffer </div>
              <div className={'input small ui ' + styles.connection_input}>
                <input
                  type="text"
                  disabled={!localBot}
                  value={idBuffer}
                  onChange={({target: {value}}) => {
                    setIdBuffer(value);
                    setChanged(true);
                  }}
                />
              </div>
            </div>
          )}
          <div className={styles.input_container}>
            <div className={styles.connection_text}>{translations[appLanguage]['g133']}</div>
            <form className={'ui form ' + styles.connection_input}>
              <input
                disabled={!localBot}
                value={note}
                onChange={({target: {value}}) => {
                  setNote(value);
                  setChanged(true);
                }}
                placeholder={translations[appLanguage]['g134']}
              />
            </form>
          </div>
          <div className={checkBox_container}>
            <div className={input_container}>
              <Checkbox
                label={translations[appLanguage]['g77']}
                checked={isActive}
                onChange={(e, {checked}) => {
                  setIsActive(checked);
                  setChanged(true);
                }}
              />
            </div>

            {/* {user?.isMaster && (
              <div className={input_container}>
                <Checkbox
                  label={translations[appLanguage]['g92']}
                  checked={idBufferReady}
                  onChange={(e, {checked}) => {
                    setIdBufferReady(checked);
                    setChanged(true);
                  }}
                />
              </div>
            )} */}
            {user?.isMaster && (
              <div className={input_container}>
                <Checkbox
                  label={'Ödeme İste'}
                  checked={paymentNeeded}
                  onChange={(e, {checked}) => {
                    setPaymentNeeded(checked);
                    setChanged(true);
                  }}
                />
              </div>
            )}
            {user?.isMaster && (
              <div className={input_container}>
                <Checkbox
                  label={'Otomatik Kapatma'}
                  checked={dontClose}
                  onChange={(e, {checked}) => {
                    setDontClose(checked);
                    setChanged(true);
                  }}
                />
              </div>
            )}
            {user?.isMaster && (
              <div className={input_container}>
                <Checkbox
                  label={'Şifre yanlış'}
                  checked={wrongPassWord}
                  onChange={(e, {checked}) => {
                    setWrongPassWord(checked);
                    setChanged(true);
                  }}
                />
              </div>
            )}
            {user?.isMaster && (
              <div className={input_container}>
                <Checkbox
                  label={'Bizim Farm'}
                  checked={isFarm}
                  onChange={(e, {checked}) => {
                    setIsFarm(checked);
                    setChanged(true);
                  }}
                />
              </div>
            )}
            {user?.isMaster && (
              <div className={input_container}>
                <Dropdown
                  value={botType}
                  onChange={(e, {value}) => {
                    setBotType(value);
                    setChanged(true);
                  }}
                  placeholder="Bot Tipi"
                  options={[
                    {
                      key: '0',
                      value: 0,
                      text: 'Eski',
                    },
                    {
                      key: '1',
                      value: 1,
                      text: 'Farm',
                    },
                    {
                      key: '2',
                      value: 2,
                      text: 'Askeri Çağrı',
                    },
                    {
                      key: '3',
                      value: 3,
                      text: 'Yardım Botu',
                    },
                  ]}
                />
              </div>
            )}
            {user?.isMaster && renderStart()}
          </div>
          <div className={buttons_container}>
            {renderSave()}
            <div>
              <Button
                secondary
                onClick={() => {
                  let pageSettings = {id: owner};
                  props.history.push('/moderator/userEdit' + JSON.stringify(pageSettings));
                }}
              >
                {translations[appLanguage]['g132']}
              </Button>
            </div>
          </div>
          {/* </div> */}
        </Tab.Pane>
      ),
    },
    {
      menuItem: translations[appLanguage]['g137'],
      render: () => (
        <Tab.Pane>
          <div className={styles.listContainer}>
            <div className={styles.listTableContainer}>
              <table className="table-container ui celled striped table">
                {renderPaymentsHead()}
                <tbody>{renderPaymentsList()}</tbody>
              </table>
            </div>
          </div>

          {user.isMaster && (
            <div>
              <Button primary onClick={() => onNewPaymentClick()}>
                {translations[appLanguage]['g140']}
              </Button>
            </div>
          )}
        </Tab.Pane>
      ),
    },
  ];

  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g91']}>
      <div className={connection_tab_container}>
        <Tab panes={panes} activeIndex={tab} onTabChange={(e, {activeIndex}) => setTab(activeIndex)} />
      </div>
    </PageHeader>
  );
}
