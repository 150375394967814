import React, {useState, useContext, useEffect} from 'react';
import classnames from 'classnames';
import {Checkbox, Select, Input, Button} from 'semantic-ui-react';

import {translations} from 'resources';
import {LanguageContext, UserContext} from 'context';
import styles from './userEdit.module.css';
import {apiURL, axiosClient} from 'service';
import {parsePageSettings} from '../../../helpers/page';
import PageHeader from '../../components/pageHeader';

export default function UserEdit(props) {
  let filter = {
    sort: '-createdOn',
    pages: {
      size: 50,
      number: 0,
    },
    select: {
      // confirmed: true,
      // draft: false,
      // finished: true
    },
  };

  let pageSettings = parsePageSettings(props.match.params.id);
  const [deletingList, setDeletingList] = useState([]);
  const [smallScreen, setSmallScreen] = useState(false);
  const [changed, setChanged] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [moderatorName, setModeratorName] = useState('');
  const [_id, set_id] = useState('');
  const [subBots, setSubBots] = useState([]);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);

  const {
    state: {user},
    getUser,
    pushUser,
  } = useContext(UserContext);

  const onResize = () => {
    if (window.innerWidth < 800) {
      setSmallScreen(true);
    } else setSmallScreen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    getUser();
  }, []);

  const renderSave = () => {
    if (changed)
      return (
        <div>
          <Button
            primary
            onClick={() => {
              const pushObj = {_id, firstName, lastName, phone, moderatorName};
              pushUser(pushObj);
              setChanged(false);
            }}
          >
            {translations[appLanguage]['g76']}
          </Button>
        </div>
      );
  };

  //DELETE AND EDIT FUNCTIONS
  const deleteBot = async (_id) => {
    setDeletingList([...deletingList, _id]);
    filter = JSON.stringify(filter);
    const response = await axiosClient.delete(apiURL.bot + '?filter=' + filter, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
      data: {
        _id,
      },
    });
    await getSubBots();
    // setDeletingList(deletingList.filter((el) => el._id !== _id));
  };

  const onAddNewBotClick = async (botType) => {
    const updateResponse = await axiosClient.post(
      apiURL.bot,
      {userId: pageSettings.id, botType},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );
    getSubBots();
  };

  const getSubUser = async () => {
    const result = await axiosClient.get(apiURL.subUser + '?id=' + pageSettings.id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (result?.data) {
      const user = result.data;
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setPhone(user.phone);
      setModeratorName(user.moderatorName);
      set_id(user._id);
    }
  };

  const getSubBots = async () => {
    const result = await axiosClient.get(apiURL.subBots + '?id=' + _id, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (result?.data) setSubBots(result.data);
  };

  useEffect(() => {
    if (pageSettings.id) getSubUser();
  }, [pageSettings.id]);

  useEffect(() => {
    if (_id) getSubBots();
  }, [_id]);

  const onEditClick = (bot) => {
    let pageSettings = {id: bot._id || ''};
    props.history.push('/moderator/botEdit' + JSON.stringify(pageSettings));
  };

  const statusTextGenerator = (bot, user) => {
    if (bot.isActive) {
      if (bot.started) return translations[appLanguage]['g77'];
      else return translations[appLanguage]['g89'];
    } else if (bot.wrongPassWord) return translations[appLanguage]['g82'];
    else if (!bot.passWord || !bot.email) return translations[appLanguage]['g90'];
    else if (!user?.moderatorName) return 'Moderator needed';
    else if (!bot.idBufferReady) return 'IDBuffer';
  };

  const renderHead = () => {
    if (!smallScreen) {
      return (
        <thead>
          <tr>
            <th colSpan="1">{translations[appLanguage]['g33']}</th>
            <th colSpan="1">{translations[appLanguage]['g85']}</th>
            <th colSpan="1">{translations[appLanguage]['g87']}</th>
            <th colSpan="1">{translations[appLanguage]['g83']}</th>
            <th colSpan="1">{translations[appLanguage]['g50']}</th>
            <th colSpan="1">{translations[appLanguage]['g88']}</th>
            <th colSpan="1"> </th>
          </tr>
        </thead>
      );
    }
  };

  const renderList = () => {
    return subBots
      ?.sort((a, b) => Date.parse(a.createdOn) - Date.parse(b.createdOn))
      .map?.((bot, i) => {
        if (deletingList.indexOf(bot._id) < 0)
          return (
            <tr key={bot._id}>
              <td style={{textTransform: 'capitalize'}}>
                {(smallScreen ? translations[appLanguage]['g33'] + ' ' : '') + bot.server}
              </td>
              {/* <td>{(smallScreen ? "Soyisim: " : "") + work.customer.lastName}</td> */}
              <td>{(smallScreen ? translations[appLanguage]['g85'] + ' ' : '') + bot.gameNick}</td>
              <td>{(smallScreen ? translations[appLanguage]['g87'] + ' ' : '') + bot.email}</td>
              <td>
                {(smallScreen ? translations[appLanguage]['g87'] + ' ' : '') +
                  statusTextGenerator(bot, {moderatorName})}
              </td>
              <td>
                {(smallScreen ? translations[appLanguage]['g50'] + ' ' : '') + new Date(bot.createdOn).toLocaleString()}
              </td>
              <td>
                {(smallScreen ? translations[appLanguage]['g88'] + ' ' : '') +
                  new Date(bot.finishDate).toLocaleString()}
              </td>
              <td className={styles.iconLabelContainer}>
                <div className={styles.iconContainer}>
                  <div>
                    <button
                      className="ui button icon blue"
                      icon
                      onClick={() => {
                        onEditClick(bot);
                      }}
                    >
                      <i style={{color: 'white'}} className="pencil alternate icon large" />
                    </button>
                  </div>
                  {user.isMaster && (
                    <div>
                      <button className="ui button icon red" icon onClick={() => deleteBot(bot._id)}>
                        <i style={{color: 'white'}} className="trash icon large" />
                      </button>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
      });
  };

  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g52']}>
      <div className={styles.setting_tab_container}>
        {/* <div className={styles.input_container}>
          <Checkbox label={translations[appLanguage]['g12']} />
        </div> */}

        <div className={styles.input_container}>
          <div className={styles.settings_text}>{translations[appLanguage]['g54'] + ':'}</div>
          <Input
            className={styles.settings_input}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              setChanged(true);
            }}
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{translations[appLanguage]['g55'] + ':'}</div>
          <Input
            className={styles.settings_input}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              setChanged(true);
            }}
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{translations[appLanguage]['g56'] + ':'}</div>
          <Input
            className={styles.settings_input}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setChanged(true);
            }}
          />
        </div>
        {user.isMaster && (
          <div className={styles.input_container}>
            <div className={styles.settings_text}>{translations[appLanguage]['g93'] + ' :'}</div>
            <Input
              className={styles.settings_input}
              value={moderatorName}
              onChange={(e) => {
                setModeratorName(e.target.value);
                setChanged(true);
              }}
            />
          </div>
        )}
        {renderSave()}
        <div className={styles.listTableContainer}>
          <table className="table-container ui celled striped table">
            {renderHead()}
            <tbody>{renderList()}</tbody>
          </table>
        </div>
        <div>
          <div onClick={() => onAddNewBotClick(0)} className="ui button green">
            {translations[appLanguage]['g60']}
          </div>
        </div>
        {user.isMaster && (
          <div>
            <div onClick={() => onAddNewBotClick(3)} className="ui button green">
              {'Yardım Botu Ekle'}
            </div>
          </div>
        )}
      </div>
    </PageHeader>
  );
}
