export default {
  signIn: '/login',
  signUp: '/register',
  user: '/user',
  userSearch: '/userSearch',
  subUser: '/subUser',
  bot: '/bot',
  botAll: '/botAll',
  subBots: '/subBots',
  botFull: '/botFull',
  botSearch: '/botSearch',
  status: '/status',
  payment: '/payment',
  paymentsOfBot: '/paymentsOfBot',
  paymentSearch: '/paymentSearch',
  generateShortID: '/generateShortID',
  modBots: '/modBots',
  chatPlayers: '/chat/players',
  chatMessages: '/chat/messages',
  chatSend: '/chat/send',
  adSend: '/chat/advertSend',
  saveMessage: '/messageSave',
  getMessage: '/getMessages',
  // base: process.env.REACT_APP_DEVL ? 'http://localhost:8080' : undefined,
  // base: process.env.REACT_APP_DEVL ? 'https://forgetpasttrade.com' : undefined,
};
