import { baseReducer } from 'context/shared'
import { SIGN_IN_USER_SUCCESS, SET_ERROR } from 'context/signIn/keys';

export const initialState = {
  isSignInSuccess: false,
  token: '',
  user: {},
  error: ''
};

export default baseReducer((state = initialState, {type,payload}) => {
  switch (type) {
    case SIGN_IN_USER_SUCCESS:
      return { ...state, isLoading: false, isSignInSuccess: true };
    case SET_ERROR:
      return { ...state, error: payload }
    default:
      return state;
  }
})
