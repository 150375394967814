import createDataContext from 'context/createDataContext';
import reducer, {initialState} from './reducer';
import * as actions from './actions';
import * as sharedActions from 'context/shared/actions';

export const {Context: MessageContext, Provider: MessageProvider} = createDataContext(
  reducer,
  {...actions, ...sharedActions},
  initialState
);
