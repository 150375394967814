import React, {useState, useContext, useEffect} from 'react';
import {Tab} from 'semantic-ui-react';

import PageHeader from '../../components/pageHeader';
import {LanguageContext, UserContext} from 'context';
import {translations} from 'resources';
import Users from 'ui/pages/users';
import Bots from 'ui/pages/moderator-bots';

export default function ModeratorPage(props) {
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {user},
    getUser,
  } = useContext(UserContext);

  useEffect(() => {
    getUser();
  }, []);

  const panes = [
    {
      menuItem: translations[appLanguage]['g84'],
      render: () => (
        <Tab.Pane attached={false}>
          <Bots {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: translations[appLanguage]['g176'],
      render: () => (
        <Tab.Pane attached={false}>
          <Users {...props} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g53']}>
      <div style={{margin: '20px'}}>
        <Tab menu={{attached: false, tabular: false}} panes={panes} />
      </div>
    </PageHeader>
  );
}
