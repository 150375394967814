import React, {useState, useContext, useEffect} from 'react';
import {translations} from 'resources';
import {LanguageContext, UserContext} from 'context';
import styles from './payments.module.css';
import TableSearchBar from '../../components/tableSearchBar';
import PageHeader from '../../components/pageHeader';
import {parsePageSettings} from '../../../helpers/page';

import {apiURL, axiosClient} from 'service';

const packageTypes = {
  ac: 0,
  standart: 1,
  premium: 2,
  farm: 3,
  shield: 4,
};

export default function PaymentsPAge(props) {
  let filter = {
    sort: '-createdOn',
    pages: {
      size: 500,
      number: 0,
    },
    select: {
      // confirmed: true,
      // draft: false,
      // finished: true
    },
  };

  const [smallScreen, setSmallScreen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [paymentList, setPaymentList] = useState([]);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {user},
  } = useContext(UserContext);

  let pageSettings = parsePageSettings(props.match.params.id);

  const onResize = () => {
    if (window.innerWidth < 800) {
      setSmallScreen(true);
    } else setSmallScreen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
  }, []);

  const onEditClick = (payment) => {
    let pageSettings = {id: payment.botId || ''};
    props.history.push('/moderator/botEdit' + JSON.stringify(pageSettings));
  };

  // //DELETE AND EDIT FUNCTIONS
  // const deleteUser = async (_id) => {
  //   setDeletingList(...deletingList, _id);
  //   let newList = botList.filter((el) => el._id !== _id);
  //   setBotList(newList);

  //   filter = JSON.stringify(filter);
  //   const response = await axiosClient.delete(apiURL.user + '?filter=' + filter, {
  //     headers: {
  //       Authorization: 'ARGA ' + localStorage.getItem('token'),
  //     },
  //     data: {
  //       _id,
  //     },
  //   });
  //   setDeletingList(deletingList.filter((el) => el._id !== _id));
  // };

  const renderHead = () => {
    if (!smallScreen) {
      return (
        <thead>
          <tr>
            <th colSpan="1">#</th>
            <th colSpan="1">{translations[appLanguage]['g40']}</th>
            <th colSpan="1">{translations[appLanguage]['g87']}</th>
            <th colSpan="1">{translations[appLanguage]['g139']}</th>
            <th colSpan="1">{translations[appLanguage]['g138']}</th>
            <th colSpan="1">{translations[appLanguage]['g111']}</th>

            <th colSpan="1">{translations[appLanguage]['g133']}</th>
            <th colSpan="1">{'ID'}</th>
            <th colSpan="1"> </th>
          </tr>
        </thead>
      );
    }
  };

  const renderList = () => {
    return paymentList?.map?.((payment, i) => {
      return (
        <tr key={payment._id}>
          <td>{(smallScreen ? '# ' : '') + (1 + i)}</td>
          <td>
            {(smallScreen ? translations[appLanguage]['g40'] + ' ' : '') + new Date(payment.date).toLocaleString()}
          </td>
          <td>{(smallScreen ? translations[appLanguage]['g87'] + ' ' : '') + payment.botEmail}</td>
          <td>
            {(smallScreen ? translations[appLanguage]['g139'] + ' ' : '') +
              Object.keys(packageTypes)[payment.packetType]}
          </td>
          <td>{(smallScreen ? translations[appLanguage]['g138'] + ' ' : '') + payment.days}</td>
          <td>{(smallScreen ? translations[appLanguage]['g111'] + ' ' : '') + payment.amount}</td>

          <td>{(smallScreen ? translations[appLanguage]['g133'] + ' ' : '') + payment.note}</td>
          <td>{(smallScreen ? 'ID ' : '') + payment.shortid}</td>

          <td className={styles.iconLabelContainer}>
            <div className={styles.iconContainer}>
              <div>
                <button
                  className="ui button icon blue"
                  icon
                  onClick={() => {
                    onEditClick(payment);
                  }}
                >
                  <i style={{color: 'white'}} className="pencil alternate icon large" />
                </button>
              </div>
              {/* <div>
                  <button className="ui button icon red" icon onClick={() => deleteBot(bot._id)}>
                    <i style={{color: 'white'}} className="trash icon large" />
                  </button>
                </div> */}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g84']}>
      <div className={styles.listContainer}>
        <TableSearchBar
          filter={filter}
          searchUrl={apiURL.paymentSearch}
          onDataReceived={(data, search) => {
            setPaymentList(data);
            if (search !== undefined) {
              let pageSettings = {search};
              props.history.push('/moderator/payments' + JSON.stringify(pageSettings));
            }
          }}
          initialSearchValue={pageSettings.search}
        />
        <div className={styles.listTableContainer}>
          <table className="table-container ui celled striped table">
            {renderHead()}
            <tbody>{renderList()}</tbody>
          </table>
        </div>
      </div>
    </PageHeader>
  );
}
