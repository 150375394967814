import React, {useState, useContext, useEffect} from 'react';
import classnames from 'classnames';
import {Button} from 'semantic-ui-react';

import {translations} from 'resources';
import {LanguageContext, UserContext} from 'context';
import {apiURL, axiosClient} from 'service';
import TopBar from 'ui/components/top-bar';
import {parsePageSettings} from '../../../helpers/page';
import TableSearchBar from '../../components/tableSearchBar';
import PageHeader from '../../components/pageHeader';
import styles from './users.module.css';

export default function ModeratorPage(props) {
  let filter = {
    sort: '-createdOn',
    pages: {
      size: 1000,
      number: 0,
    },
    select: {
      // confirmed: true,
      // draft: false,
      // finished: true
    },
  };

  const [smallScreen, setSmallScreen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [userList, setUserList] = useState([]);
  const [deletingList, setDeletingList] = useState([]);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {user},
    getUser,
  } = useContext(UserContext);

  const onResize = () => {
    if (window.innerWidth < 800) {
      setSmallScreen(true);
    } else setSmallScreen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    getUser();
    onResize();
  }, []);

  const onEditClick = (user) => {
    let pageSettings = {id: user._id || ''};
    props.history.push('/moderator/userEdit' + JSON.stringify(pageSettings));
  };

  //DELETE AND EDIT FUNCTIONS
  const deleteUser = async (_id) => {
    setDeletingList(...deletingList, _id);
    let newList = userList.filter((el) => el._id !== _id);
    setUserList(newList);

    filter = JSON.stringify(filter);
    const response = await axiosClient.delete(apiURL.user + '?filter=' + filter, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
      data: {
        _id,
      },
    });
    setDeletingList(deletingList.filter((el) => el._id !== _id));
  };

  const renderHead = () => {
    if (!smallScreen) {
      return (
        <thead>
          <tr>
            <th colSpan="1">#</th>
            <th colSpan="1">{translations[appLanguage]['g93']}</th>
            <th colSpan="1">{translations[appLanguage]['g47']}</th>
            <th colSpan="1">{translations[appLanguage]['g48']}</th>
            <th colSpan="1">{translations[appLanguage]['g49']}</th>
            <th colSpan="1">{translations[appLanguage]['g50']}</th>
            <th colSpan="1"> </th>
          </tr>
        </thead>
      );
    }
  };

  const renderList = () => {
    return userList?.map((el, i) => {
      if (deletingList.indexOf(el._id) < 0)
        return (
          <tr key={el._id}>
            <td style={{textTransform: 'capitalize'}}>{i + 1}</td>
            <td style={{textTransform: 'capitalize'}}>
              {(smallScreen ? translations[appLanguage]['g93'] + ' ' : '') + (el.moderatorName || '--')}
            </td>
            <td style={{textTransform: 'capitalize'}}>
              {(smallScreen ? translations[appLanguage]['g47'] + ' ' : '') +
                (el.firstName || '--') +
                ' ' +
                (el.lastName || ' ')}
            </td>
            {/* <td>{(smallScreen ? "Soyisim: " : "") + work.customer.lastName}</td> */}
            <td>{(smallScreen ? translations[appLanguage]['g48'] + ' ' : '') + el.email}</td>
            <td>{(smallScreen ? translations[appLanguage]['g49'] + ' ' : '') + (el.phone || '--')}</td>
            <td>
              {(smallScreen ? translations[appLanguage]['g50'] + ' ' : '') + new Date(el.createdOn).toLocaleString()}
            </td>

            <td className={styles.iconLabelContainer}>
              <div className={styles.iconContainer}>
                <div>
                  <button
                    className="ui button icon blue"
                    icon
                    onClick={() => {
                      onEditClick(el);
                    }}
                  >
                    <i style={{color: 'white'}} className="pencil alternate icon large" />
                  </button>
                </div>
                {user.isMaster && (
                  <div>
                    <button className="ui button icon red" icon onClick={() => deleteUser(el._id)}>
                      <i style={{color: 'white'}} className="trash icon large" />
                    </button>
                  </div>
                )}
              </div>
            </td>
          </tr>
        );
    });
  };

  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g53']}>
      {/* <TopBar /> */}
      <div className={styles.listContainer}>
        <TableSearchBar
          filter={filter}
          searchUrl={apiURL.userSearch}
          onDataReceived={(data, search) => {
            setUserList(data);
          }}
          initialSearchValue={''}
        />
        <div className={styles.listTableContainer}>
          <table className="table-container ui celled striped table">
            {renderHead()}
            <tbody>{renderList()}</tbody>
          </table>
        </div>
      </div>
    </PageHeader>
  );
}
