import React, {useState, useContext, useEffect} from 'react';
import {Button, Input, Select} from 'semantic-ui-react';

import {translations} from 'resources';
import {LanguageContext, UserContext, BotContext} from 'context';
import TopBar from 'ui/components/top-bar';
import styles from './payment.module.css';
import PageHeader from 'ui/components/pageHeader';

export default function UserEdit(props) {
  const [smallScreen, setSmallScreen] = useState(false);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {selectedIndex, bots},
    getBots,
    pushBot,
    setIndex,
  } = useContext(BotContext);
  const {
    state: {user},
    getUser,
  } = useContext(UserContext);

  const onResize = () => {
    if (window.innerWidth < 800) {
      setSmallScreen(true);
    } else setSmallScreen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    getBots();
    getUser();
  }, []);

  const renderHead = () => {
    if (!smallScreen) {
      return (
        <thead>
          <tr>
            <th colSpan="1">{'#'}</th>
            <th colSpan="1">{translations[appLanguage]['g33']}</th>
            <th colSpan="1">{translations[appLanguage]['g85']}</th>
            <th colSpan="1">{translations[appLanguage]['g87']}</th>
            <th colSpan="1">{'ID'}</th>
            <th colSpan="1">{translations[appLanguage]['g88']}</th>
          </tr>
        </thead>
      );
    }
  };

  const renderList = () => {
    return bots
      ?.sort?.((a, b) => (b?.gameNick < a?.gameNick ? 1 : -1))
      ?.map((bot, i) => {
        let lineStyle = new Date(bot.finishDate) < new Date() ? {color: 'red'} : {};

        return (
          <tr style={lineStyle} key={bot._id}>
            <td>{i + 1}</td>
            <td style={{textTransform: 'capitalize'}}>
              {(smallScreen ? translations[appLanguage]['g33'] + ' : ' : '') + bot.server}
            </td>
            {/* <td>{(smallScreen ? "Soyisim: " : "") + work.customer.lastName}</td> */}
            <td>{(smallScreen ? translations[appLanguage]['g85'] + ' : ' : '') + bot.gameNick}</td>
            <td>{(smallScreen ? translations[appLanguage]['g87'] + ' : ' : '') + bot.email}</td>
            <td style={{fontWeight: 'bold'}}>
              {(smallScreen ? translations[appLanguage]['g146'] + ' : ' : '') + bot.iggID}
            </td>
            <td>
              {(smallScreen ? translations[appLanguage]['g88'] + ' : ' : '') +
                new Date(bot.finishDate).toLocaleString()}
            </td>
          </tr>
        );
      });
  };
  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g94']}>
      <TopBar />

      <div className={styles.main_container}>
        <div style={{fontWeight: 'bold', color: 'red', fontSize: '16px'}}>{translations[appLanguage]['g147']}</div>
        <div style={{fontWeight: 'bold', color: 'red', fontSize: '16px'}}>{translations[appLanguage]['g148']}</div>
        <table className="table-container ui celled striped table">
          {renderHead()}
          <tbody>{renderList()}</tbody>
        </table>
      </div>
    </PageHeader>
  );
}
