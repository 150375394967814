import {SET_LOADING} from 'context/shared';
import {apiURL, axiosClient} from 'service';
import {SIGN_IN_USER_SUCCESS, SET_ERROR} from 'context/signIn/keys';

export const signInUser = (dispatch) => async (user) => {
  dispatch({type: SET_LOADING});
  const {success, token, error} = (await axiosClient.post(apiURL.signIn, user)).data;

  if (success) {
    dispatch({type: SIGN_IN_USER_SUCCESS});
    localStorage.setItem('token', token);
  } else {
    dispatch({type: SET_ERROR, payload: error});
  }
};
