import React, {useState, useContext, useEffect} from 'react';
import {Button, Input, Select} from 'semantic-ui-react';
import DatePicker, {registerLocale} from 'react-datepicker';

import {translations} from 'resources';
import {LanguageContext, UserContext} from 'context';
import TopBar from 'ui/components/top-bar';
import styles from './paymentEdit.module.css';
import PageHeader from '../../components/pageHeader';
import {parsePageSettings} from '../../../helpers/page';
import {apiURL, axiosClient} from 'service';

const packageTypes = {
  ac: 0,
  standart: 1,
  premium: 2,
  farm: 3,
  shield: 4,
};

const packageOptions = Object.keys(packageTypes).map((el) => ({key: el, text: el, value: packageTypes[el]}));

export default function PaymentEdit(props) {
  const [_id, set_id] = useState('');
  const [note, setNote] = useState('');
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(new Date());
  const [packetType, setPacketType] = useState(0);
  const [days, setDays] = useState(30);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);

  const pageSetting = parsePageSettings(props.match.params.id);
  const {botId, paymentId} = pageSetting;

  const pushPayment = async (payment) => {
    if (!paymentId) {
      const {data} = await axiosClient.post(apiURL.payment, payment, {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      });
    } else {
      const {data} = await axiosClient.put(apiURL.payment, payment, {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      });
    }

    props.history.goBack();
  };

  const getPayment = async (paymentId) => {
    const {data} = await axiosClient.get(apiURL.payment + '?_id=' + paymentId, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (data?._id) {
      setNote(data.node);
      setAmount(data.amount);
      setDate(new Date(data.date));
      setPacketType(data.packetType);
      setDays(data.days);
      set_id(data._id);
    }
  };

  useEffect(() => {
    if (paymentId) {
      getPayment(paymentId);
    }
  }, [paymentId]);

  const renderSave = () => {
    return (
      <div>
        <Button
          primary
          onClick={() => {
            const pushObj = {amount, note, botId, date, packetType, days};
            if (_id) pushObj._id = _id;
            pushPayment(pushObj);
          }}
        >
          Kaydet
        </Button>
      </div>
    );
  };
  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g94']}>
      <TopBar />
      <div className={styles.setting_tab_container}>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{'Tutar:'}</div>
          <Input
            className={styles.settings_input}
            value={amount}
            onChange={(e) => {
              const amount = parseInt(e.target.value) || 0;
              setAmount(amount);
            }}
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{'Tarih:'}</div>
          <DatePicker
            locale="tr"
            dateFormat="dd/MM/yyyy"
            className="fluid"
            selected={date}
            onChange={(date) => {
              setDate(date);
            }}
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{'Paket:'}</div>
          <Select
            value={packetType}
            onChange={(e, {value}) => {
              setPacketType(value);
            }}
            className={styles.input_container}
            placeholder="Paket"
            options={packageOptions}
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{'Günler:'}</div>
          <Input
            className={styles.settings_input}
            value={days}
            onChange={(e) => {
              const days = parseInt(e.target.value) || 0;
              setDays(days);
            }}
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{'Açıklama:'}</div>
          <form className={'ui form ' + styles.connection_input}>
            <textarea
              value={note}
              onChange={({target: {value}}) => setNote(value)}
              placeholder={'Ödeme hakkında açıklama'}
            />
          </form>
        </div>

        {renderSave()}
      </div>
    </PageHeader>
  );
}
