import createDataContext from 'context/createDataContext';
import reducer, {initialState} from 'context/bot/reducer';
import * as actions from 'context/bot/actions';
import * as sharedActions from 'context/shared/actions';

export const {Context: BotContext, Provider: BotProvider} = createDataContext(
  reducer,
  {...actions, ...sharedActions},
  initialState
);
