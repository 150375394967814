import React, {useState, useContext, useEffect} from 'react';
import {Button} from 'semantic-ui-react';

import {translations} from 'resources';
import {LanguageContext, UserContext} from 'context';

import PageHeader from '../../components/pageHeader';

import styles from './messages.module.css';

import {apiURL, axiosClient} from 'service';

export default function Messages(props) {
  const [message1, setMessage1] = useState('');
  const [message2, setMessage2] = useState('');
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);

  const saveMessages = async () => {
    const response = await axiosClient.post(
      apiURL.saveMessage,
      {
        message1,
        message2,
      },
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );
    if (response?.data) {
      if (response?.data?._id) {
        alert('Message Saved ');
      }
    }
  };
  const getMessages = async () => {
    console.log('get messages');
    const response = await axiosClient.get(apiURL.getMessage, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (response?.data) {
      console.log('response get', response?.data);
      setMessage1(response.data[0].message1);
      setMessage2(response.data[0].message2);
    }
  };

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g84']}>
      <div>
        <textarea
          className={styles.textAreaStyle}
          value={message1}
          onChange={({target: {value}}) => {
            setMessage1(value);
          }}
          placeholder={translations[appLanguage]['g134']}
        />
      </div>
      <div>
        <textarea
          className={styles.textAreaStyle}
          value={message2}
          onChange={({target: {value}}) => {
            setMessage2(value);
          }}
          placeholder={translations[appLanguage]['g134']}
        />
      </div>
      <div>
        <Button
          primary
          onClick={() => {
            saveMessages();
          }}
        >
          {translations[appLanguage]['g76']}
        </Button>
      </div>
    </PageHeader>
  );
}
