import React, {useState, useContext, useEffect} from 'react';
import {Button, Input, Select} from 'semantic-ui-react';

import {translations} from 'resources';
import {LanguageContext, UserContext} from 'context';
import TopBar from 'ui/components/top-bar';
import styles from './profileEdit.module.css';
import PageHeader from '../../components/pageHeader';

export default function UserEdit(props) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [moderatorName, setModeratorName] = useState('');
  const [changed, setChanged] = useState('');
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {user},
    getUser,
    pushUser,
    resetUser,
  } = useContext(UserContext);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    setEmail(user.email);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setPhone(user.phone);
    setModeratorName(user.moderatorName);
    setChanged(false);
  }, [user]);

  const renderSave = () => {
    if (changed)
      return (
        <div>
          <Button
            primary
            onClick={() => {
              const pushObj = {_id: user._id, email, firstName, lastName, phone};
              if (moderatorName) pushObj.moderatorName = moderatorName;
              pushUser(pushObj);
              setChanged(false);
              props.history.goBack();
            }}
          >
            {translations[appLanguage]['g76']}
          </Button>
        </div>
      );
  };
  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g94']}>
      <TopBar />
      <div className={styles.setting_tab_container}>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{translations[appLanguage]['g54'] + ':'}</div>
          <Input
            className={styles.settings_input}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              setChanged(true);
            }}
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{translations[appLanguage]['g55'] + ':'}</div>
          <Input
            className={styles.settings_input}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              setChanged(true);
            }}
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{translations[appLanguage]['g56'] + ':'}</div>
          <Input
            className={styles.settings_input}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setChanged(true);
            }}
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{translations[appLanguage]['g87'] + ':'}</div>
          <Input className={styles.settings_input} value={email} />
        </div>
        <div className={styles.input_container}>
          <div className={styles.settings_text}>{translations[appLanguage]['g93'] + ' :'}</div>
          <Input
            className={styles.settings_input}
            value={moderatorName}
            onChange={(e) => {
              setModeratorName(e.target.value);
              setChanged(true);
            }}
          />
          {/* <Select
            value={moderatorName}
            onChange={(e, {value}) => {
              setModeratorName(value);
              setChanged(true);
            }}
            className={styles.settings_input}
            placeholder="Moderator"
            //TODO: optionsları başka yerde topla
            options={[
              {
                key: 'Eyup',
                text: 'Eyup',
                value: 'Eyup',
              },
              {
                key: 'Sanchez',
                text: 'Sanchez',
                value: 'Sanchez',
              },
              {
                key: 'Albus',
                text: 'Albus',
                value: 'Albus',
              },
              {
                key: 'Ateş',
                text: 'Ateş',
                value: 'Ateş',
              },
              {
                key: 'Pro',
                text: 'Pro',
                value: 'Pro',
              },
              {
                key: 'Fatma',
                text: 'Fatma',
                value: 'Fatma',
              },
              {
                key: 'CezaPompasi',
                text: 'CezaPompasi',
                value: 'CezaPompasi',
              },
            ]}
          /> */}
        </div>
        {(phone || '').length < 10 && <div style={{color: 'red'}}>{translations[appLanguage]['g141']}</div>}

        {renderSave()}
        {/* <div className={styles.btn_container}>
          <Button
            fluid
            right
            color='red'
            onClick={() => {
              localStorage.setItem('token', '');
              resetUser();
              props.history.push('/signin');
            }}
          >
            {translations[appLanguage]['g62']}
          </Button>
        </div> */}
      </div>
    </PageHeader>
  );
}
