import React, {useState, useContext, useEffect} from 'react';
import {translations} from 'resources';
import {Checkbox} from 'semantic-ui-react';
import {LanguageContext, UserContext} from 'context';
import styles from './bots.module.css';
import TableSearchBar from '../../components/tableSearchBar';
import PageHeader from '../../components/pageHeader';
import {parsePageSettings} from '../../../helpers/page';

import {apiURL, axiosClient} from 'service';

export default function BotPage(props) {
  let filter = {
    sort: '-createdOn',
    pages: {
      size: 2000,
      number: 0,
    },
    select: {
      // gameNick: 1,
      // ownerEmail: 1,
      // email: 1,
      // createdOn: 1,
      // finishDate: 1,
      // server: 1,
      // passWord: 1,
      // idBufferReady: 1,
      // started: 1,
      // isActive: 1,
      // wrongPassWord: 1,
      // shortid: 1,
    },
  };

  const [smallScreen, setSmallScreen] = useState(false);
  const [inFarm, setInFarm] = useState(false);
  const [inHelper, setInHelper] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [botList, setBotList] = useState([]);
  const [deletingList, setDeletingList] = useState([]);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {user},
  } = useContext(UserContext);

  let pageSettings = parsePageSettings(props.match.params.id);

  const onResize = () => {
    if (window.innerWidth < 800) {
      setSmallScreen(true);
    } else setSmallScreen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
  }, []);

  const onEditClick = (bot) => {
    let pageSettings = {id: bot._id || ''};
    props.history.push('/moderator/botEdit' + JSON.stringify(pageSettings));
  };

  const onAdMessageClick = async (bot) => {
    const res = await axiosClient.post(
      apiURL.adSend,
      {_id: bot._id},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );

    console.log('res', res);
  };

  // //DELETE AND EDIT FUNCTIONS
  // const deleteUser = async (_id) => {
  //   setDeletingList(...deletingList, _id);
  //   let newList = botList.filter((el) => el._id !== _id);
  //   setBotList(newList);

  //   filter = JSON.stringify(filter);
  //   const response = await axiosClient.delete(apiURL.user + '?filter=' + filter, {
  //     headers: {
  //       Authorization: 'ARGA ' + localStorage.getItem('token'),
  //     },
  //     data: {
  //       _id,
  //     },
  //   });
  //   setDeletingList(deletingList.filter((el) => el._id !== _id));
  // };

  const renderHead = () => {
    if (!smallScreen) {
      return (
        <thead>
          <tr>
            <th colSpan="1">#</th>
            <th colSpan="1">{translations[appLanguage]['g33']}</th>
            <th colSpan="1">{translations[appLanguage]['g85']}</th>
            <th colSpan="1">{translations[appLanguage]['g86']}</th>
            <th colSpan="1">{translations[appLanguage]['g87']}</th>
            <th colSpan="1">{'ID'}</th>
            <th colSpan="1">{translations[appLanguage]['g83']}</th>
            <th colSpan="1">{translations[appLanguage]['g50']}</th>
            <th colSpan="1">{translations[appLanguage]['g88']}</th>
            <th colSpan="1"> </th>
          </tr>
        </thead>
      );
    }
  };

  const statusTextGenerator = (bot, user) => {
    if (bot.isActive) {
      if (bot.started) return translations[appLanguage]['g77'];
      else return translations[appLanguage]['g89'];
    } else if (bot.wrongPassWord) return translations[appLanguage]['g82'];
    else if (!bot.passWord || !bot.email) return translations[appLanguage]['g90'];
    else if (!user?.moderatorName) return 'Moderator needed';
    else if (!bot.idBufferReady) return 'IDBuffer';
    else return 'Passive';
  };

  //DELETE AND EDIT FUNCTIONS
  const deleteBot = async (_id) => {
    setDeletingList([...deletingList, _id]);
    filter = JSON.stringify(filter);
    const response = await axiosClient.delete(apiURL.bot + '?filter=' + filter, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
      data: {
        _id,
      },
    });
    // await getSubBots();
    // setDeletingList(deletingList.filter((el) => el._id !== _id));
  };

  const renderList = () => {
    return botList
      ?.sort((a, b) => Date.parse(a.createdOn) - Date.parse(b.createdOn))
      .map?.((bot, i) => {
        if (deletingList.indexOf(bot._id) < 0)
          return (
            <tr key={bot._id}>
              <td>{(smallScreen ? '# ' : '') + (1 + i)}</td>

              <td style={{textTransform: 'capitalize'}}>
                {(smallScreen ? translations[appLanguage]['g33'] + ' ' : '') + bot.server}
              </td>
              {/* <td>{(smallScreen ? "Soyisim: " : "") + work.customer.lastName}</td> */}
              <td>{(smallScreen ? translations[appLanguage]['g85'] + ' ' : '') + bot.gameNick}</td>
              <td>{(smallScreen ? translations[appLanguage]['g86'] + ' ' : '') + bot.ownerEmail}</td>
              <td>{(smallScreen ? translations[appLanguage]['g87'] + ' ' : '') + bot.email}</td>
              <td>{(smallScreen ? translations[appLanguage]['g88'] + ' ' : '') + bot.iggID}</td>
              <td>{(smallScreen ? translations[appLanguage]['g87'] + ' ' : '') + statusTextGenerator(bot, user)}</td>
              <td>
                {(smallScreen ? translations[appLanguage]['g50'] + ' ' : '') + new Date(bot.createdOn).toLocaleString()}
              </td>
              <td>
                {(smallScreen ? translations[appLanguage]['g88'] + ' ' : '') +
                  new Date(bot.finishDate).toLocaleString()}
              </td>
              <td className={styles.iconLabelContainer}>
                <div className={styles.iconContainer}>
                  <div>
                    <button
                      className="ui button icon blue"
                      icon
                      onClick={() => {
                        onEditClick(bot);
                      }}
                    >
                      <i style={{color: 'white'}} className="pencil alternate icon large" />
                    </button>
                  </div>

                  <div>
                    <button className="ui button icon green" icon onClick={() => onAdMessageClick(bot)}>
                      <i style={{color: 'white'}} className="mail icon large" />
                    </button>
                  </div>

                  {/* <div>
                  <button className="ui button icon red" icon onClick={() => deleteBot(bot._id)}>
                    <i style={{color: 'white'}} className="trash icon large" />
                  </button>
                </div> */}
                </div>
              </td>
            </tr>
          );
      });
  };

  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g84']}>
      <div className={styles.listContainer}>
        <TableSearchBar
          body={{inFarm, inHelper}}
          filter={filter}
          pick={{
            gameNick: 1,
            ownerEmail: 1,
            email: 1,
            createdOn: 1,
            finishDate: 1,
            server: 1,
            idBufferReady: 1,
            started: 1,
            isActive: 1,
            shortid: 1,
            passWord: 1,
            iggID: 1,
          }}
          searchUrl={apiURL.botSearch}
          onDataReceived={(data, search) => {
            setBotList(data);
            if (search !== undefined) {
              let pageSettings = {search};
              props.history.push('/moderator/bots' + JSON.stringify(pageSettings));
            }
          }}
          initialSearchValue={pageSettings.search}
        />
        <div>
          <Checkbox label="in Farm" checked={inFarm} onChange={(e, {checked}) => setInFarm(checked)} />
        </div>
        <div>
          <Checkbox label="in Helper" checked={inHelper} onChange={(e, {checked}) => setInHelper(checked)} />
        </div>
        <div className={styles.listTableContainer}>
          <table className="table-container ui celled striped table">
            {renderHead()}
            <tbody>{renderList()}</tbody>
          </table>
        </div>
      </div>
    </PageHeader>
  );
}
