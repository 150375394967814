import React, {useState, useContext, useEffect} from 'react';
import {Checkbox, Button, Select, Dropdown, Menu} from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';

import {translations} from 'resources';
import {LanguageContext, BotContext} from 'context';
import styles from './connection.module.css';
import iggInfo from 'resources/images/igg-id-nfo.jpg';

const kingdomOptions = () => {
  let returnThis = [];
  for (let a = 1; a < 200; a++) returnThis.push({key: '' + a, value: a, text: '' + a});
  return returnThis;
};

export default function Connection(props) {
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [email, setEmail] = useState('');
  const [iggID, setIggID] = useState('');
  const [gameNick, setName] = useState('');
  const [server, setServer] = useState(1);
  const [currentServer, setCurrentServer] = useState(1);
  const [isGmail, setIsGmail] = useState('');
  const [changePass, setChangePass] = useState(false);
  const [changed, setChanged] = useState(false);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {selectedIndex, bots},
    pushBot,
    setBot,
  } = useContext(BotContext);
  const bot = bots?.[selectedIndex];
  const {
    connection_tab_container,
    input_container,
    connection_input,
    checkbox_container,
    checkbox,
    connection_text,
  } = styles;

  useEffect(() => {
    setEmail(bot?.email);
    setIggID(bot?.iggID || '');
    setIsGmail(bot?.isGmail);
    setName(bot?.gameNick);
    setServer(bot?.server || 1);
    setCurrentServer(bot?.server || 1);
    if (!bot?.passWord) setChangePass(true);
    else setChangePass(false);
    if (bot?.wrongPassWord) setChangePass(true);
    if (bot?.wrongEmail) setChangePass(true);
  }, [bots, selectedIndex]);

  const renderPass = () => {
    if (changePass || !bot?.passWord)
      return (
        <div className={styles.input_container}>
          <div className={styles.connection_text}>{translations[appLanguage]['g34']} </div>
          <div className={'input small ui ' + styles.connection_input}>
            <input
              type="password"
              autocomplete="new-password"
              disabled={!bot}
              value={pass1}
              onChange={({target: {value}}) => {
                setPass1(value);
                setChanged(true);
              }}
              // onChange={({target: {value}}) => {
              //   setBot({_id: bot._id, passWord: value});
              // }}
              // onBlur={() => pushBot({...bot})}
            />
          </div>
        </div>
      );
  };

  const renderRePass = () => {
    if (changePass || !bot?.passWord)
      return (
        <div className={styles.input_container}>
          <div className={styles.connection_text}>{translations[appLanguage]['g74']} </div>
          <div className={'input small ui ' + styles.connection_input}>
            <input
              type="password"
              autocomplete="new-password"
              disabled={!bot}
              value={pass2}
              onChange={({target: {value}}) => {
                setPass2(value);
                setChanged(true);
              }}
              // onChange={({target: {value}}) => {
              //   setBot({_id: bot._id, passWord: value});
              // }}
              // onBlur={() => pushBot({...bot})}
            />
          </div>
        </div>
      );
  };

  const renderChangePass = () => {
    if (bot?.passWord)
      return (
        <div className={input_container}>
          <Checkbox
            label={translations[appLanguage]['g75']}
            checked={changePass}
            onChange={(e, {checked}) => setChangePass(checked)}
          />
        </div>
      );
  };
  const renderSave = () => {
    if (changePass && !pass1) return;
    if (changePass && !pass2) return;
    if (changePass && pass1 !== pass2) return <div className={styles.Error}> {translations[appLanguage]['g81']}</div>;
    if (changed)
      return (
        <div>
          <Button
            primary
            onClick={async () => {
              const newBot = {_id: bot._id, email, isGmail, server, gameNick, iggID};
              if (changePass) newBot.passWord = pass1;
              else delete newBot.passWord;
              props.NM.info(translations[appLanguage]['g187'], '', 2000);
              await pushBot(newBot);
              // props.NM.info('Mail Gönderiliyor', '', 50000, null, false);
              props.NM.success(translations[appLanguage]['g188'], '', 2000);
              setChanged(false);
              setChangePass(false);
              let pageSettings = {tab: 1, index: selectedIndex};
              props.history.push('/panel' + JSON.stringify(pageSettings));
            }}
          >
            {translations[appLanguage]['g76']}
          </Button>
        </div>
      );
  };

  return (
    <div className={connection_tab_container}>
      <div className={input_container}>
        <div className={connection_text}>{translations[appLanguage]['g33'] + ' :'}</div>
        <Dropdown
              value={server}
              onChange={(e, {value}) => {
                setServer(value);
                setChanged(true);
              }}
              search
              clearable
              selection
              className={connection_input}
              placeholder="Kingdom"
              options={kingdomOptions()}
            />
        {/* <Menu compact className={connection_input}>
          <Dropdown
            value={server}
            onChange={(e, {value}) => {
              setServer(value);
              setChanged(true);
            }}
            placeholder="Kingdom"
            options={kingdomOptions()}
            item
          />
        </Menu> */}
      </div>
      <div className={styles.input_container}>
        <div className={styles.connection_text}>{translations[appLanguage]['g3']} </div>
        <div className={'input small ui ' + styles.connection_input}>
          <input
            type="text"
            disabled={!bot}
            value={email}
            onChange={({target: {value}}) => {
              setEmail(value);
              setChanged(true);
            }}
            // onBlur={() => pushBot({...bot})}
          />
        </div>
        <a className={styles.Info} data-tip={translations[appLanguage]['g98']}>
          ℹ
        </a>
        <ReactTooltip place="top" type="dark" effect="solid" />
      </div>
      <div className={styles.input_container}>
        <div className={styles.connection_text}>{translations[appLanguage]['g85']} </div>
        <div className={'input small ui ' + styles.connection_input}>
          <input
            type="text"
            disabled={!bot}
            value={gameNick}
            onChange={({target: {value}}) => {
              setName(value);
              setChanged(true);
            }}
            // onBlur={() => pushBot({...bot})}
          />
        </div>
      </div>
      {renderChangePass()}
      {renderPass()}
      {renderRePass()}
      <div className={input_container}>
        <div className={connection_text}>{translations[appLanguage]['g35'] + ' :'}</div>
        <Select
          value={isGmail ? 2 : 1}
          onChange={(e, {value}) => {
            setIsGmail(value === 2);
            setChanged(true);
          }}
          className={connection_input}
          placeholder="isGmail"
          options={[
            {key: translations[appLanguage]['g36'], text: translations[appLanguage]['g36'], value: 1},
            // {key: translations[appLanguage]['g37'], text: translations[appLanguage]['g37'], value: 2},
          ]}
        />
      </div>
      {!isGmail && (
        <div className={styles.input_container}>
          <div className={styles.connection_text}>igg id : </div>
          <div className={'input small ui ' + styles.connection_input}>
            <input
              type="text"
              disabled={!bot}
              value={iggID}
              onChange={({target: {value}}) => {
                setIggID(value);
                setChanged(true);
              }}
              // onBlur={() => pushBot({...bot})}
            />
          </div>
          <a className={styles.Info} data-tip data-for="overridePosition">
            ℹ
          </a>
          <ReactTooltip
            id="overridePosition"
            overridePosition={({left, top}, currentEvent, currentTarget, node) => {
              const d = document.documentElement;
              left = Math.min(d.clientWidth - node.clientWidth, left);
              top = Math.min(d.clientHeight - node.clientHeight, top);
              left = Math.max(0, left);
              top = Math.max(0, top);
              return {top, left};
            }}
          >
            <img style={{width: '300px'}} src={iggInfo} alt={'igg id info'} />
            <div>igg id</div>
          </ReactTooltip>
        </div>
      )}
      {(changePass || currentServer !== server) && <div style={{color: 'red'}}>{translations[appLanguage]['g97']}</div>}
      {renderSave()}
    </div>
  );
}
