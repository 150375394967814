import React, {useState, useContext, useEffect} from 'react';
import {Button} from 'semantic-ui-react';
import classnames from 'classnames';

import {translations} from 'resources';
import {LanguageContext, BotContext, UserContext, MessageContext} from 'context';
import TopBar from 'ui/components/top-bar';
import BotsBox from '../../components/botsBox';
import PageHeader from '../../components/pageHeader';
import BuildingSelection from './tabs/building-selection';
import SettingsTab from './tabs/settings-tab';
import Connection from './tabs/connection';
import Messages from './tabs/messages';
import styles from './landing-page.module.css';
import {parsePageSettings} from '../../../helpers/page';
import {apiURL, axiosClient} from 'service';
import './landing-page.css';
import {UnderlineOutlined} from '@ant-design/icons';

const statuses = {
  moderatorNameMissing: 1,
  paused: 2,
  waitingApproval: 3,
  timeFinished: 4,
  wrongPassWord: 5,
  connectionInfoMissing: 6,
  militaryEventWillStart: 7,
  militaryEventStarted: 8,
  active: 9,
  closing: 10,
  error: 11,
  dataWaiting: 12,
  starting: 13,
  paymentNeeded: 14,
  wrongEmail: 15,
  unKnownEmail: 16,
  iggIdWrong: 17,
};

let globalBotID = '';
let globalIggId = '';
let globalLastSended = 0;
// let globalBotStatus = statuses.dataWaiting;
let timeoutID;
let waiting = false;
let routineEnabled = false;

export default function LandingPage(props) {
  const [activeTab, setActiveTab] = useState(parsePageSettings(props.match.params.id)?.tab);
  const [globalBotStatus, setGlobalBotStatus] = useState(statuses.dataWaiting);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [paymentNeeded, setPaymentNeeded] = useState(false);
  const [oldGlobalBotStatus, setoldGlobalBotStatus] = useState(statuses.dataWaiting);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {selectedIndex, bots},
    getBots,
    pushBot,
    setIndex,
  } = useContext(BotContext);

  const {
    state: {user},
    getUser,
    resetUser,
    pushUser,
  } = useContext(UserContext);
  const {
    state: {selectedIggId, lastSendedMillis, players},
    setPlayers,
    setMessages,
  } = useContext(MessageContext);
  const pageSettings = parsePageSettings(props.match.params.id);

  const bot = bots?.[selectedIndex];

  const changeTab = (tab) => {
    if (!localStorage.getItem('token')) {
      props.history.push('/');

      console.log("localStorage.getItem('token')", localStorage.getItem('token'));
      return;
    }
    pageSettings.tab = tab;
    if (tab === 4) pageSettings.noTawk = true;
    else delete pageSettings.noTawk;
    props.history.push('/panel' + JSON.stringify(pageSettings));
  };

  const statusText = () => {
    // if (globalBotStatus === statuses.paymentNeeded) return translations[appLanguage]['g145'];

    if (globalBotStatus === statuses.active) return translations[appLanguage]['g77'];
    else if (globalBotStatus === statuses.closing) return translations[appLanguage]['g112'];
    else if (globalBotStatus === statuses.connectionInfoMissing) return translations[appLanguage]['g80'];
    else if (globalBotStatus === statuses.dataWaiting) return translations[appLanguage]['g113'];
    else if (globalBotStatus === statuses.error) return translations[appLanguage]['g114'];
    else if (globalBotStatus === statuses.militaryEventStarted) return translations[appLanguage]['g102'];
    else if (globalBotStatus === statuses.militaryEventWillStart) return translations[appLanguage]['g115'];
    else if (globalBotStatus === statuses.moderatorNameMissing) return translations[appLanguage]['g116'];
    else if (globalBotStatus === statuses.paused) return translations[appLanguage]['g99'];
    else if (globalBotStatus === statuses.waitingApproval) return translations[appLanguage]['g79'];
    else if (globalBotStatus === statuses.wrongPassWord) return translations[appLanguage]['g82'];
    else if (globalBotStatus === statuses.wrongEmail) return translations[appLanguage]['g183'];
    else if (globalBotStatus === statuses.unKnownEmail) return translations[appLanguage]['g184'];
    else if (globalBotStatus === statuses.iggIdWrong) return translations[appLanguage]['g186'];
    else if (globalBotStatus === statuses.timeFinished) return translations[appLanguage]['g117'];
    else if (globalBotStatus === statuses.starting) return translations[appLanguage]['g118'];
    else if (globalBotStatus === statuses.starting) return translations[appLanguage]['g118'];
  };

  const statusStyle = () => {
    // if (globalBotStatus === statuses.paymentNeeded) return styles.ErrorClick;
    // else
    if (globalBotStatus === statuses.active) return styles.Ok;
    else if (globalBotStatus === statuses.closing) return styles.Wait;
    else if (globalBotStatus === statuses.connectionInfoMissing) return styles.WaitClick;
    else if (globalBotStatus === statuses.dataWaiting) return styles.Wait;
    else if (globalBotStatus === statuses.error) return styles.Error;
    else if (globalBotStatus === statuses.militaryEventStarted) return styles.Ok;
    else if (globalBotStatus === statuses.militaryEventWillStart) return styles.Wait;
    else if (globalBotStatus === statuses.moderatorNameMissing) return styles.WaitClick;
    else if (globalBotStatus === statuses.paused) return styles.Wait;
    else if (globalBotStatus === statuses.waitingApproval) return styles.Wait;
    else if (globalBotStatus === statuses.wrongPassWord) return styles.ErrorClick;
    else if (globalBotStatus === statuses.wrongEmail) return styles.ErrorClick;
    else if (globalBotStatus === statuses.unKnownEmail) return styles.ErrorClick;
    else if (globalBotStatus === statuses.iggIdWrong) return styles.ErrorClick;
    else if (globalBotStatus === statuses.timeFinished) return styles.WaitClick;
    else if (globalBotStatus === statuses.starting) return styles.Wait;
  };

  const statusRoutine = async () => {
    clearInterval(timeoutID);
    timeoutID = setInterval(async () => {
      if (!routineEnabled) return;
      if (waiting) return;
      if (globalBotID) {
        waiting = true;
        try {
          console.log('-------');

          const {data} = await axiosClient.get(apiURL.status + '?_id=' + globalBotID + '&iggId=' + globalIggId, {
            headers: {Authorization: 'ARGA ' + localStorage.getItem('token')},
          });
          // console.log('lastSendedMillis', lastSendedMillis);

          // console.log('data?.iggId', data?.iggId);
          // console.log('data?.messages', data?.messages);
          // console.log('data?.messages &&  data?.iggId', data?.messages && data?.iggId);
          if (data?.messages && data?.iggId && Date.now() - (globalLastSended || 0) > 4000) {
            // console.log('data?.messages***', globalIggId, data?.messages);

            if (data.iggId === globalIggId) {
              setMessages(data?.messages, data?.iggId);
            }
          }

          if (data?.players) {
            // let isNewMessage = false;
            // data.players.forEach((el) => {
            //   const localPlayer = players?.find?.((item) => item.iggId === el.iggId);
            //   if (!localPlayer || el.unread > localPlayer.unread) isNewMessage = true;
            //   console.log('localPlayer', localPlayer, players, el.iggId);
            // });
            // if (isNewMessage) props.NM.success(translations[appLanguage]['g189']);

            setPlayers(data?.players);
          }

          // if (data?.botStatus) globalBotStatus = data.botStatus;
          if (data?.botStatus) {
            setGlobalBotStatus((globalBotStatus) => data.botStatus);
          }

          setDays(data?.remainingDays);
          setHours(data?.remainHours);
          setPaymentNeeded(data?.paymentNeeded);
        } catch (err) {}
        waiting = false;
      } else console.log('globalBotID', globalBotID);
    }, 1000);
  };
  useEffect(() => {
    if (
      globalBotStatus === statuses.active &&
      oldGlobalBotStatus !== statuses.active &&
      oldGlobalBotStatus !== statuses.dataWaiting
    )
      window.location.reload(true);
    if (globalBotStatus === statuses.active && oldGlobalBotStatus !== statuses.active) window.scrollTo(0, 0);
    setoldGlobalBotStatus(globalBotStatus);
  }, [globalBotStatus]);
  useEffect(() => {
    globalBotID = '';
    getUser();
    statusRoutine();

    return () => (routineEnabled = false);
  }, []);

  useEffect(() => {
    getBots();
  }, [user]);

  useEffect(() => {
    changeTab(activeTab);
  }, [activeTab, bot?.botType]);

  useEffect(() => {
    globalIggId = selectedIggId;
  }, [selectedIggId]);
  useEffect(() => {
    globalLastSended = lastSendedMillis;
  }, [lastSendedMillis]);
  useEffect(() => {
    if (selectedIndex >= 0) {
      pageSettings.index = selectedIndex;
      props.history.push('/panel' + JSON.stringify(pageSettings));
    }
  }, [selectedIndex]);

  useEffect(() => {
    setActiveTab(parsePageSettings(props.match.params.id)?.tab || 2);
    setIndex(parsePageSettings(props.match.params.id)?.index || 0);
  }, [props.match.params.id]);

  useEffect(() => {
    if (selectedIndex === -1 && bots?.length > 0) setIndex(0);

    if (bot) {
      globalBotID = bot._id;
      routineEnabled = true;
    }
  }, [bots]);

  useEffect(() => {
    if (players) setUnreadMessages(players?.reduce?.((p, c) => p + (c.unread || 0), 0));
  }, [players]);
  const prepareBotOptions = () => {
    return bots?.map((bot, i) => ({
      key: bot.gameNick && bot.server ? bot.gameNick + ' |' + bot.server : 'Bot ' + (i + 1),
      text: bot.gameNick && bot.server ? bot.gameNick + ' |' + bot.server : 'Bot ' + (i + 1),
      value: i,
    }));
  };

  const renderStartButton = () => {
    if (bot?.isActive)
      return (
        <Button color="red" onClick={() => pushBot({_id: bot._id, userStarted: !bot.userStarted})}>
          {bot.userStarted ? translations[appLanguage]['g95'] : translations[appLanguage]['g8']}
        </Button>
      );
  };

  return (
    <PageHeader hideBackButton={true} history={props.history} pageName={translations[appLanguage]['g61']}>
      <div className={styles.landing_page_container}>
        <TopBar startBtn={renderStartButton()} />

        <div className={styles.header_container}>
          <div className={styles.head_container}>
            <div
              className={styles.status_container}
              style={{flexDirection: appLanguage === 'arb' ? 'row-reverse' : 'unset'}}
            >
              <div
                onClick={() => {
                  if (globalBotStatus === statuses.moderatorNameMissing) props.history.push('/profileEdit');
                  // else if (globalBotStatus === statuses.paymentNeeded) props.history.push('/payment');
                  else if (
                    globalBotStatus === statuses.connectionInfoMissing ||
                    globalBotStatus === statuses.wrongPassWord ||
                    globalBotStatus === statuses.wrongEmail ||
                    globalBotStatus === statuses.unKnownEmail ||
                    globalBotStatus === statuses.iggIdWrong
                  )
                    changeTab(3);
                }}
                className={styles.statusContainer + ' ' + statusStyle()}
              >
                {statusText()}
              </div>
              <BotsBox
                botOptions={prepareBotOptions()}
                onBotSelect={(i) => {
                  getBots();
                  setIndex(i);
                }}
              />
            </div>
            {paymentNeeded ? (
              <div onClick={() => props.history.push('/payment')} className={styles.warning_container}>
                {translations[appLanguage]['g145']}
              </div>
            ) : (
              <div
                className={styles.day_indicator_container}
                style={{flexDirection: appLanguage === 'arb' ? 'row-reverse' : 'unset'}}
              >
                {translations[appLanguage]['g150'] +
                  ' : ' +
                  days +
                  ' ' +
                  translations[appLanguage]['g174'] +
                  ' ' +
                  hours +
                  ' ' +
                  translations[appLanguage]['g175']}
                {/* (bot && new Date(bot.finishDate))} */}
              </div>
            )}
            <div style={{textAlign: '-webkit-center', color: 'red'}}>
              <div className={appLanguage === 'arb' ? styles.kayanyazireverse : styles.kayanyazi}>
                <span>
                  {
                    appLanguage === 'arb'
                      ? // translations[appLanguage]['g169'] +
                        //   '😂😂😂' +
                        //   ' /// ' +
                        //   translations[appLanguage]['g172'] +
                        //   '😳😳😳' +
                        //   ' /// ' +
                        //   translations[appLanguage]['g179'] +
                        //   '😍😍😍' +
                        translations[appLanguage]['g185']
                      : translations[appLanguage]['g185']
                    // +
                    //   '😍😍😍' +
                    //   translations[appLanguage]['g179'] +
                    //   ' /// ' +
                    //   '😳😳😳' +
                    //   translations[appLanguage]['g172'] +
                    //   ' /// ' +
                    //   '😂😂😂' +
                    //   translations[appLanguage]['g169']
                  }
                </span>
              </div>
            </div>
          </div>

          <div className={styles.tabs_container}>
            <div
              className={classnames(styles.tab_pane_container, {[styles.active]: activeTab === 1})}
              // onClick={() => setActiveTab(1)}
              onClick={() => changeTab(1)}
            >
              {translations[appLanguage]['g9']}
            </div>

            <div
              className={classnames(styles.tab_pane_container, {[styles.active]: activeTab === 2})}
              onClick={() => changeTab(2)}
            >
              {translations[appLanguage]['g10']}
            </div>
            <div
              className={classnames(styles.tab_pane_container, {[styles.active]: activeTab === 3})}
              onClick={() => changeTab(3)}
            >
              {translations[appLanguage]['g31']}
            </div>
            <div
              style={{color: 'red'}}
              className={classnames(styles.tab_pane_container, {[styles.active]: activeTab === 4})}
              onClick={() => changeTab(4)}
            >
              {translations[appLanguage]['g177'] + (unreadMessages ? '(' + unreadMessages + ')' : '')}
            </div>
          </div>
        </div>
        {activeTab === 1 && <BuildingSelection {...props} />}
        {activeTab === 2 && <SettingsTab {...props} />}
        {activeTab === 3 && <Connection {...props} />}
        {activeTab === 4 && <Messages {...props} />}
      </div>
    </PageHeader>
  );
}
