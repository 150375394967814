import React, {useEffect, useContext} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import detectBrowserLanguage from 'detect-browser-language';
import {NotificationManager as NM, NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import history from './history';
import {LanguageContext} from 'context';
//Pages
import LandingPage from 'ui/pages/landing-page';
import ModeratorPage from 'ui/pages/moderator';
import BotsPage from 'ui/pages/bots';
import ModeratorBotsPage from 'ui/pages/moderator-bots';
import UserEdit from 'ui/pages/user-edit';
import BotEdit from 'ui/pages/bot-edit';
import ProfileEdit from 'ui/pages/profileEdit';
import PaymentEdit from 'ui/pages/paymentEdit';
import Payments from 'ui/pages/payments';
import Payment from 'ui/pages/payment';
import Onboarding from 'ui/pages/onboarding';
import Signin from 'ui/pages/signin';
import Signup from 'ui/pages/signup';
import Messages from 'ui/pages/messages';

function App() {
  const {
    changeAppLanguage,
    state: {languageSetted},
  } = useContext(LanguageContext);

  useEffect(() => {
    if (!languageSetted) {
      let lang = detectBrowserLanguage();
      if (lang === 'tr' || lang === 'tr-TR') lang = 'tr';
      else if (lang === 'ar') lang = 'arb';
      else lang = 'en';
      changeAppLanguage(lang);
    }
  }, []);

  return (
    <div>
      <Router history={history}>
        <div>
          <Switch>
            <Route path="/" exact render={(props) => <Onboarding {...props} NM={NM} />} />
            <Route path="/panel" exact render={(props) => <LandingPage {...props} NM={NM} />} />
            <Route path="/panel:id" exact render={(props) => <LandingPage {...props} NM={NM} />} />
            <Route path="/signin" exact component={Signin} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/profileEdit" exact component={ProfileEdit} />
            <Route path="/payment" exact component={Payment} />
            <Route path="/moderator/payments" exact component={Payments} />
            <Route path="/moderator/payments:id" exact component={Payments} />
            <Route path="/moderator/payment" exact component={PaymentEdit} />
            <Route path="/moderator/payment:id" exact component={PaymentEdit} />
            <Route path="/moderator" exact component={ModeratorPage} />
            <Route path="/moderator:id" exact component={ModeratorPage} />
            <Route path="/moderator/bots" exact component={BotsPage} />
            <Route path="/modBots" exact component={ModeratorBotsPage} />
            <Route path="/moderator/bots:id" exact component={BotsPage} />
            <Route path="/moderator/userEdit:id" exact component={UserEdit} />
            <Route path="/moderator/botEdit:id" exact component={BotEdit} />
            <Route path="/moderator/messages" exact component={Messages} />
          </Switch>
          <NotificationContainer />
        </div>
      </Router>
    </div>
  );
}

export default App;
