import {SET_ERROR, SET_LOADING} from 'context/shared';
import {apiURL, axiosClient} from 'service';
import {SET_BOTS, SET_INDEX, SET_SINGLE_BOT, RESET_BOTS} from 'context/bot/keys';

export const getBots = (dispatch) => async () => {
  dispatch({type: SET_LOADING});
  const {data} = await axiosClient.get(apiURL.bot, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });
  if (Array.isArray(data)) {
    dispatch({type: SET_BOTS, payload: data});
  }
};

export const getAllBots = (dispatch) => async () => {
  dispatch({type: SET_LOADING});
  const {data} = await axiosClient.get(apiURL.botAll, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });
  if (Array.isArray(data)) {
    dispatch({type: SET_BOTS, payload: data});
  }
};

export const getBot = (dispatch) => async (_id) => {
  dispatch({type: SET_LOADING});
  const {data} = await axiosClient.get(apiURL.bot + '?_id=' + _id, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });
  if (data) {
    dispatch({type: SET_SINGLE_BOT, payload: data});
  }
};

//TODO: send only changed parts
export const pushBot = (dispatch) => async (bot, startEventNow) => {
  if (!startEventNow) bot.startEventNow = false;
  delete bot.eventProgressing;
  if (!bot._id) throw 'id needed';
  dispatch({type: SET_LOADING});
  dispatch({type: SET_SINGLE_BOT, payload: bot});
  const {data} = await axiosClient.put(apiURL.bot, bot, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });

  if (Array.isArray(data?.bots)) {
    dispatch({type: SET_BOTS, payload: data.bots});
  }
};

export const setBot = (dispatch) => async (bot) => {
  dispatch({type: SET_SINGLE_BOT, payload: bot});
};

export const setIndex = (dispatch) => (index) => {
  dispatch({type: SET_INDEX, payload: index});
};

export const resetBots = (dispatch) => async () => {
  dispatch({type: RESET_BOTS});
};
