import React, {useContext, useState, useEffect} from 'react';
import {Button, Input, Icon} from 'semantic-ui-react';
import DatePicker, {registerLocale} from 'react-datepicker';
import tr from 'date-fns/locale/tr';
import 'react-datepicker/dist/react-datepicker.css';
import search from '../../../helpers/search';
import styles from './TableSearchBar.module.css';
import {translations} from 'resources';
import {LanguageContext} from 'context';
import _ from 'lodash';
registerLocale('tr', tr);

let intId;
const defaultStartDate = new Date().setFullYear(2019);
const TableSearchBar = ({style, onDataReceived, filter, pick, body, searchUrl, initialSearchValue}) => {
  const [smallScreen, setSmallScreen] = useState(checkSmallScreen());
  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState(new Date(defaultStartDate));
  const [finishDate, setFinishDate] = useState(new Date());
  const [modified, setModified] = useState(false);
  const [initialSearch, setInitialSearch] = useState(true);

  const {
    state: {appLanguage},
  } = useContext(LanguageContext);

  function checkSmallScreen() {
    if (window.innerWidth < 900) return true;
    else return false;
  }
  const onResize = () => {
    if (checkSmallScreen()) {
      setSmallScreen(true);
    } else setSmallScreen(false);
  };

  const searchUser = async (searchText) => {
    filter.select.createdOn = {Dgt: startDate, Dlt: finishDate};

    const response = await search(searchUrl, searchText, filter, pick, body || {});

    if (onDataReceived && response) {
      onDataReceived(response, initialSearch ? undefined : searchText);
      if (initialSearch) setInitialSearch(false);
    }
  };

  const onSearchInput = async (value) => {
    // setSearchValue(value);
    searchUser(value);
    // if (value) searchWork(value);
    // else {
    //   if (onEmpty) onEmpty();
    // }
  };

  useEffect(() => {
    if (!initialSearch) searchUser(searchValue);
  }, [startDate, finishDate]);

  useEffect(() => {
    if (initialSearchValue) {
      setSearchValue(initialSearchValue);
      searchUser(initialSearchValue);
    } else searchUser('');
  }, []);
  useEffect(() => {
    if (initialSearchValue) {
      setSearchValue(initialSearchValue);
      searchUser(initialSearchValue);
    } else searchUser('');
  }, [JSON.stringify(body)]);

  const renderCleanButton = () => {
    if (modified || searchValue)
      return (
        <div>
          <button
            className="ui button icon red"
            icon
            onClick={() => {
              setStartDate(new Date(defaultStartDate));
              setFinishDate(new Date());
              setSearchValue('');
              setModified(false);
            }}
          >
            {translations[appLanguage]['g43']}

            <i style={{color: 'white'}} className="delete icon " />
          </button>
        </div>
      );
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
  }, []);

  return (
    <div style={style} className={styles.TableSearchBarContainer + ' ui form'}>
      <div className={styles.searchBarDatePickerContainer}>
        <div className={'fluid ui ' + styles.searchBarDateLabel}>{translations[appLanguage]['g44']}</div>
        <DatePicker
          locale="tr"
          dateFormat="dd/MM/yyyy"
          className="fluid"
          selected={new Date(startDate)}
          onChange={(startDate) => {
            setModified(true);
            if (startDate > finishDate) {
              startDate.setDate(finishDate.getDate() - 1);
            }
            setStartDate(startDate);
          }}
        />
      </div>

      <div className={styles.searchBarDatePickerContainer}>
        <div className={'fluid ui ' + styles.searchBarDateLabel}>{translations[appLanguage]['g45']}</div>
        <DatePicker
          locale="tr"
          dateFormat="dd/MM/yyyy"
          className="fluid"
          selected={new Date(finishDate)}
          onChange={(finishDate) => {
            setModified(true);
            if (startDate > finishDate) {
              finishDate.setDate(startDate.getDate() + 1);
            }
            setFinishDate(finishDate);
          }}
        />
      </div>
      <Input
        className={styles.searchInput}
        placeholder={translations[appLanguage]['g46']}
        value={searchValue}
        startdate={startDate}
        finishdate={finishDate}
        onChange={(e, {value}) => {
          setSearchValue(value);
          clearTimeout(intId);
          intId = setTimeout(() => {
            onSearchInput(value);
          }, 600);
        }}
      />
      {renderCleanButton()}
    </div>
  );
};

export default TableSearchBar;
