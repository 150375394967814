import React, {useContext, useEffect, useRef, useState} from 'react';
import {Input, Button, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {useForm} from 'hooks';
import {translations} from 'resources';
import {isEmail, isEmpty, isValidPassword} from 'utils';
import {SignUpContext, LanguageContext} from 'context';
import ayyildiz_logo from 'resources/images/ayyildiz_logo.png';
import styles from './signup.module.css';

export default function Signup({history}) {
  const {
    state: {isSignUpSuccess, signUpError},
    signUpUser,
    resetState,
  } = useContext(SignUpContext);
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const [showPassword, setShowPassword] = useState(false);
  const emailInput = useRef();
  const passwordInput = useRef();
  const {
    form: {email, password, confirmPassword, validate},
  } = useForm({
    email: {
      value: '',
      getError: (value) => (isEmpty(value) || !isEmail(value) ? translations[appLanguage]['g2'] : ''),
    },
    password: {
      value: '',
      getError: (value) => (isValidPassword(value) ? '' : translations[appLanguage]['g7']),
    },
    confirmPassword: {
      value: '',
      getError: (value) => (isValidPassword(value) ? '' : translations[appLanguage]['g7']),
    },
  });

  useEffect(() => {
    isSignUpSuccess && history.push('/panel');
    return resetState;
  }, [isSignUpSuccess]);

  const handleFormSubmission = (event) => {
    event.preventDefault();
    validate() &&
      password?.value === confirmPassword?.value &&
      signUpUser({
        // phoneNumber: phoneNumber.value,
        email: email.value,
        password: password.value,
      });
  };

  const handleKeyPress = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      event.preventDefault();
      passwordInput.current.focus();
    }
  };

  return (
    <form onSubmit={handleFormSubmission} className={styles.signup_container}>
      <Link to="/">
        <img className={styles.logo} src={ayyildiz_logo} alt="ayyildiz_logo" />
      </Link>

      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g3']}</div>
        <Input
          type="email"
          ref={emailInput}
          value={email?.value}
          onChange={(e) => email?.setValue(e.target.value)}
          placeholder={translations[appLanguage]['g3']}
          onKeyPress={handleKeyPress}
        />

        <div className={styles.error_message}>{email?.error ? email?.error : <span>&nbsp;</span>}</div>
      </div>

      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g4']}</div>
        <Input
          type="password"
          ref={passwordInput}
          value={password?.value}
          onChange={(e) => password?.setValue(e.target.value)}
          onBlur={(e) =>
            confirmPassword?.value &&
            password?.value !== confirmPassword?.value &&
            password?.setError(translations[appLanguage]['g143'])
          }
          placeholder={translations[appLanguage]['g4']}
          type={showPassword ? 'text' : 'password'}
          name="password"
        >
          <input />
          <span className={styles.eye} onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <Icon name="eye" /> : <Icon name="eye slash" />}
          </span>
        </Input>
        <div className={styles.error_message}>{password?.error ? password?.error : <span>&nbsp;</span>}</div>
      </div>

      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g142']}</div>
        <Input
          type="password"
          ref={passwordInput}
          value={confirmPassword?.value}
          onChange={(e) => confirmPassword?.setValue(e.target.value)}
          onBlur={(e) =>
            password?.value !== confirmPassword?.value && password?.setError(translations[appLanguage]['g143'])
          }
          placeholder={translations[appLanguage]['g142']}
          type={showPassword ? 'text' : 'password'}
          name="passwordConfirm"
        >
          <input />
          <span className={styles.eye} onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <Icon name="eye" /> : <Icon name="eye slash" />}
          </span>
        </Input>
        <div className={styles.error_message}>
          {confirmPassword?.error ? confirmPassword?.error : <span>&nbsp;</span>}
        </div>
      </div>
      {signUpError == 1 && <div className={styles.error_message}>{translations[appLanguage]['g144']}</div>}
      <div className={styles.input_container}>
        <Button onClick={handleFormSubmission} inverted size="large">
          {translations[appLanguage]['g5']}
        </Button>
      </div>
      <Link to="/signin" className={styles.route_text_container}>
        {translations[appLanguage]['g28']}
      </Link>
    </form>
  );
}
