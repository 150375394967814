import {baseReducer} from 'context/shared';
import {SET_INDEX, SET_BOTS, SET_SINGLE_BOT, RESET_BOTS} from 'context/bot/keys';

export const initialState = {
  bots: [],
  selectedIndex: -1,
};

export default baseReducer((state = initialState, {type, payload}) => {
  switch (type) {
    case SET_BOTS:
      return {...state, bots: payload};
    case SET_INDEX:
      return {...state, selectedIndex: payload};
    case SET_SINGLE_BOT:
      state.bots = state.bots.map((el) => (el._id === payload._id ? {...el, ...payload} : el));
      return {...state};
    case RESET_BOTS:
      return {...initialState};
    default:
      return state;
  }
});
