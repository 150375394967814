import {axiosClient} from 'service';

const search = async (searchUrl, searchText, filter, pick, body = {}) => {
  if (searchUrl) {
    const response = await axiosClient.post(
      searchUrl,
      {searchText, filter, pick, ...body},
      {
        headers: {
          Authorization: 'ARGA ' + localStorage.getItem('token'),
        },
      }
    );
    if (response?.data) return response.data;
  }

  return null;
};

export default search;
