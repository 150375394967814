import React, {useContext, useEffect, useState} from 'react';
import {Checkbox, Input, Button, Select} from 'semantic-ui-react';

import {translations} from 'resources';
import {LanguageContext, UserContext, BotContext} from 'context';
import styles from './settings-tab.module.css';

const seferOptions = [
  {value: 0, key: '1-1 ', text: '1-1 '},
  {value: 1, key: '1-3 ', text: '1-3 '},
  {value: 2, key: '1-5 ', text: '1-5 '},
  {value: 3, key: '1-7 ', text: '1-7 '},
  {value: 4, key: '1-9 ', text: '1-9 '},
  {value: 5, key: '1-11', text: '1-11'},
  {value: 6, key: '2-1 ', text: '2-1 '},
  {value: 7, key: '2-3 ', text: '2-3 '},
  {value: 8, key: '2-5 ', text: '2-5 '},
  {value: 9, key: '2-7 ', text: '2-7 '},
  {value: 10, key: '2-9 ', text: '2-9 '},
  {value: 11, key: '2-11', text: '2-11'},
  {value: 12, key: '3-1 ', text: '3-1 '},
  {value: 13, key: '3-3 ', text: '3-3 '},
  {value: 14, key: '3-5 ', text: '3-5 '},
  {value: 15, key: '3-7 ', text: '3-7 '},
  {value: 16, key: '3-9 ', text: '3-9 '},
  {value: 17, key: '3-11', text: '3-11'},
  {value: 18, key: '4-1 ', text: '4-1 '},
  {value: 19, key: '4-3 ', text: '4-3 '},
  {value: 20, key: '4-5 ', text: '4-5 '},
  {value: 21, key: '4-7 ', text: '4-7 '},
  {value: 22, key: '4-9 ', text: '4-9 '},
  {value: 23, key: '4-11', text: '4-11'},
  {value: 24, key: '5-1 ', text: '5-1 '},
  {value: 25, key: '5-3 ', text: '5-3 '},
  {value: 26, key: '5-5 ', text: '5-5 '},
  {value: 27, key: '5-7 ', text: '5-7 '},
  {value: 28, key: '5-9 ', text: '5-9 '},
  {value: 29, key: '5-11', text: '5-11'},
  {value: 30, key: '6-1 ', text: '6-1 '},
  {value: 31, key: '6-3 ', text: '6-3 '},
  {value: 32, key: '6-5 ', text: '6-5 '},
  {value: 33, key: '6-7 ', text: '6-7 '},
  {value: 34, key: '6-9 ', text: '6-9 '},
  {value: 35, key: '6-11', text: '6-11'},
  {value: 36, key: '7-1 ', text: '7-1 '},
  {value: 37, key: '7-3 ', text: '7-3 '},
  {value: 38, key: '7-5 ', text: '7-5 '},
  {value: 39, key: '7-7 ', text: '7-7 '},
  {value: 40, key: '7-9 ', text: '7-9 '},
  {value: 41, key: '7-11', text: '7-11'},
];

export default function SettingsTab(props) {
  const [showMilitaryButton, setShowMilitaryButton] = useState(true);
  const [changed, setChanged] = useState(false);
  const [autoShield, setAutoShield] = useState(false);
  const [autoSoldierCallback, setAutoSoldierCallback] = useState(false);
  const [autoSoldier, setAutoSoldier] = useState(false);
  const [collectHero, setCollectHero] = useState(false);
  const [autoKlanHelp, setAutoKlanHelp] = useState(false);
  const [autoKlanDonate, setAutoKlanDonate] = useState(false);
  const [autoEvent, setAutoEvent] = useState(false);
  const [collectLoginBox, setCollectLoginBox] = useState(false);
  const [collectDailyJobBoxes, setCollectDailyJobBoxes] = useState(false);
  const [collectTimeBoxes, setCollectTimeBoxes] = useState(false);
  const [collectVIPBoxes, setCollectVIPBoxes] = useState(false);
  const [autoKari, setAutoKari] = useState(false);
  const [collectKlanGift, setCollectKlanGift] = useState(false);
  const [collectPray, setCollectPray] = useState(false);
  const [prayType, setPrayType] = useState(false);
  const [autoHarabe, setAutoHarabe] = useState(false);
  const [autoKazi, setAutoKazi] = useState(false);
  const [kislaMax, setKislaMax] = useState(0);
  const [revirMax, setRevirMax] = useState(0);
  const [keresteMax, setKeresteMax] = useState(0);
  const [tahilMax, setTahilMax] = useState(0);
  const [madenMax, setMadenMax] = useState(0);
  const [autoSefer, setAutoSefer] = useState(false);
  const [isSeferHard, setIsSeferHard] = useState(false);
  const [autoSeferData, setAutoSeferData] = useState([]);
  const [preEventResources, setPreEventResources] = useState([]);
  const [destroySpeed, setDestroySpeed] = useState({});
  const [autoAsi, setAutoAsi] = useState(false);
  const [autoAsiLevel, setAutoAsiLevel] = useState(1);
  const [asiHero, setAsiHero] = useState([]);
  const [daily, setDaily] = useState(false);
  const [autoPearlDonate, setAutoPearlDonate] = useState(false);
  const [autoSwapBox, setAutoSwapBox] = useState(false);
  const [autoTrapBuild, setAutoTrapBuild] = useState(false);
  const [trapType, setTrapType] = useState(0);
  const [kaynakTopla, setKaynakTopla] = useState(false);
  const [kaynakSoldierFill, setKaynakSoldierFill] = useState(false);
  const [kaynakLevel, setKaynakLevel] = useState(0);
  const [kaynakData, setKaynakData] = useState([{}, {}, {}, {}, {}]);
  const [selectedHeros, setSelectedHeros] = useState({});
  const [selectedAsiHeros, setSelectedAsiHeros] = useState({});
  const [kaynakLevels, setKaynakLevels] = useState([]);

  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {user},
  } = useContext(UserContext);
  const {
    state: {selectedIndex, bots},
    pushBot,
    setBot,
  } = useContext(BotContext);
  const bot = bots?.[selectedIndex];

  const kaynakOptions = [
    {value: 0, key: '0 ', text: translations[appLanguage]['g70']},
    {value: 1, key: '1 ', text: translations[appLanguage]['g69']},
    {value: 2, key: '2 ', text: translations[appLanguage]['g71']},
  ];

  const heroOptions = [
    {value: -1, key: '-1', text: translations[appLanguage]['g165']},
    {value: 0, key: '0 ', text: 'Shujaa'},
    {value: 1, key: '1 ', text: 'Kahina'},
    {value: 2, key: '2 ', text: 'Baybars'},
    {value: 3, key: '3 ', text: 'Balqees'},
    {value: 4, key: '4 ', text: 'Nasir bin Murshid'},
    {value: 5, key: '5 ', text: 'Al Jassas'},
    {value: 6, key: '6 ', text: 'Saud'},
    {value: 7, key: '7 ', text: 'Al Muzaffar'},
    {value: 8, key: '8 ', text: 'Selim '},
    {value: 9, key: '9 ', text: 'Subh'},
    {value: 10, key: '10', text: 'Tariq'},
    {value: 11, key: '11', text: 'Alp Arslan'},
    {value: 12, key: '12', text: 'Al Basra'},
    {value: 13, key: '13', text: 'Nar'},
    {value: 14, key: '14', text: 'I.Süleyman'},
    {value: 15, key: '15', text: 'Shajar Aldurr'},
    {value: 16, key: '16', text: 'Murat Reis'},
    {value: 17, key: '17', text: 'Hurrem'},
    {value: 18, key: '18', text: 'Shaykh al Jabal'},
    {value: 19, key: '19', text: 'Kadir'},
    {value: 20, key: '20', text: 'Yusuf bin Tashfin'},
    {value: 21, key: '21', text: 'Mihrimah'},
    {value: 22, key: '22', text: 'Hammam'},
    {value: 23, key: '23', text: 'Sabreen'},
    {value: 25, key: '25', text: 'Adha'},
    {value: 26, key: '26', text: "Abu Ja'far al-Mansur"},
    {value: 27, key: '27', text: 'Antarah bin Shaddad'},
    {value: 28, key: '28', text: 'Osman'},
    {value: 29, key: '29', text: 'Sünni Ali'},
    {value: 30, key: '30', text: 'Abu Layla'},
    {value: 31, key: '31', text: 'Diponegoro'},
    {value: 32, key: '32', text: 'Kalavun'},
    {value: 33, key: '33', text: 'Fatma el-Fihri'},
    {value: 34, key: '34', text: 'Qaqa ibn Amr'},
    {value: 35, key: '35', text: 'Zenobia'},
    {value: 36, key: '36', text: 'Bayezid'},
    {value: 37, key: '37', text: "İmruü'l-Kays"},
    {value: 38, key: '38', text: 'Aleksandr Puşkin'},
    {value: 39, key: '39', text: 'El-Hansa'},
    {value: 40, key: '40', text: 'Selahaddin Eyyubi'},
    {value: 41, key: '41', text: '1. Abdurrahman'},
    {value: 42, key: '42', text: 'Kosem'},
    {value: 43, key: '43', text: 'II.Mehmet'},
    {value: 44, key: '44', text: 'Hatim al-Tai'},
  ];

  const generateHeroOptions = (item, i) => {
    return heroOptions.filter((el) => {
      if (item?.hero?.[i] === el.value) return true;
      else if (el.value < 0) return true;
      else return selectedHeros?.[bot?._id]?.[el.value] !== true;
    });
  };

  const generateAsiHeroOptions = (item, i) => {
    return heroOptions.filter((el, index) => {
      if (index < 1) return false;
      if (item?.[i] === el.value) return true;
      else return selectedAsiHeros?.[bot?._id]?.[el.value] !== true;
    });
  };

  const packageOptions = [
    {value: 0, key: '1K ' + translations[appLanguage]['g70'], text: '1K ' + translations[appLanguage]['g70']},
    {value: 1, key: '2K ' + translations[appLanguage]['g70'], text: '2K ' + translations[appLanguage]['g70']},
    {value: 2, key: '4K ' + translations[appLanguage]['g70'], text: '4K ' + translations[appLanguage]['g70']},
    {value: 3, key: '10K ' + translations[appLanguage]['g70'], text: '10K ' + translations[appLanguage]['g70']},
    {value: 4, key: '30K ' + translations[appLanguage]['g70'], text: '30K ' + translations[appLanguage]['g70']},
    {value: 5, key: '50K ' + translations[appLanguage]['g70'], text: '50K ' + translations[appLanguage]['g70']},
    {value: 6, key: '150K ' + translations[appLanguage]['g70'], text: '150K ' + translations[appLanguage]['g70']},
    {value: 7, key: '500K ' + translations[appLanguage]['g70'], text: '500K ' + translations[appLanguage]['g70']},
    {value: 8, key: '1.5M ' + translations[appLanguage]['g70'], text: '1.5M ' + translations[appLanguage]['g70']},
    {value: 9, key: '1K ' + translations[appLanguage]['g69'], text: '1K ' + translations[appLanguage]['g69']},
    {value: 10, key: '2K ' + translations[appLanguage]['g69'], text: '2K ' + translations[appLanguage]['g69']},
    {value: 11, key: '4K ' + translations[appLanguage]['g69'], text: '4K ' + translations[appLanguage]['g69']},
    {value: 12, key: '10K ' + translations[appLanguage]['g69'], text: '10K ' + translations[appLanguage]['g69']},
    {value: 13, key: '30K ' + translations[appLanguage]['g69'], text: '30K ' + translations[appLanguage]['g69']},
    {value: 14, key: '50K ' + translations[appLanguage]['g69'], text: '50K ' + translations[appLanguage]['g69']},
    {value: 15, key: '150K ' + translations[appLanguage]['g69'], text: '150K ' + translations[appLanguage]['g69']},
    {value: 16, key: '500K ' + translations[appLanguage]['g69'], text: '500K ' + translations[appLanguage]['g69']},
    {value: 17, key: '1.5M ' + translations[appLanguage]['g69'], text: '1.5M ' + translations[appLanguage]['g69']},
  ];

  const tuzakOptions = [
    {value: 0, key: '0', text: translations[appLanguage]['g136'] + ' 1 ' + translations[appLanguage]['g155']},
    {value: 1, key: '1', text: translations[appLanguage]['g136'] + ' 1 ' + translations[appLanguage]['g156']},
    {value: 2, key: '2', text: translations[appLanguage]['g136'] + ' 1 ' + translations[appLanguage]['g157']},
    {value: 3, key: '3', text: translations[appLanguage]['g136'] + ' 1 ' + translations[appLanguage]['g158']},

    {value: 4, key: '4', text: translations[appLanguage]['g136'] + ' 2 ' + translations[appLanguage]['g155']},
    {value: 5, key: '5', text: translations[appLanguage]['g136'] + ' 2 ' + translations[appLanguage]['g156']},
    {value: 6, key: '6', text: translations[appLanguage]['g136'] + ' 2 ' + translations[appLanguage]['g157']},
    {value: 7, key: '7', text: translations[appLanguage]['g136'] + ' 2 ' + translations[appLanguage]['g158']},

    {value: 8, key: '8', text: translations[appLanguage]['g136'] + ' 3 ' + translations[appLanguage]['g155']},
    {value: 9, key: '9', text: translations[appLanguage]['g136'] + ' 3 ' + translations[appLanguage]['g156']},
    {value: 10, key: '10', text: translations[appLanguage]['g136'] + ' 3 ' + translations[appLanguage]['g157']},
    {value: 11, key: '11', text: translations[appLanguage]['g136'] + ' 3 ' + translations[appLanguage]['g158']},

    {value: 12, key: '12', text: translations[appLanguage]['g136'] + ' 4 ' + translations[appLanguage]['g155']},
    {value: 13, key: '13', text: translations[appLanguage]['g136'] + ' 4 ' + translations[appLanguage]['g156']},
    {value: 14, key: '14', text: translations[appLanguage]['g136'] + ' 4 ' + translations[appLanguage]['g157']},
    {value: 15, key: '15', text: translations[appLanguage]['g136'] + ' 4 ' + translations[appLanguage]['g158']},

    {value: 16, key: '16', text: translations[appLanguage]['g136'] + ' 5 ' + translations[appLanguage]['g155']},
    {value: 17, key: '17', text: translations[appLanguage]['g136'] + ' 5 ' + translations[appLanguage]['g156']},
    {value: 18, key: '18', text: translations[appLanguage]['g136'] + ' 5 ' + translations[appLanguage]['g157']},
    {value: 19, key: '19', text: translations[appLanguage]['g136'] + ' 5 ' + translations[appLanguage]['g158']},
  ];

  const asiLevelOptions = [
    {value: 1, key: 1, text: '1'},
    {value: 2, key: 2, text: '2'},
    {value: 3, key: 3, text: '3'},
    {value: 4, key: 4, text: '4'},
    {value: 5, key: 5, text: '5'},
    {value: 6, key: 6, text: '6'},
    {value: 7, key: 7, text: '7'},
    {value: 8, key: 8, text: '8'},
    {value: 9, key: 9, text: '9'},
    {value: 10, key: 10, text: '10'},
    {value: 11, key: 11, text: '11'},
    {value: 12, key: 12, text: '12'},
    {value: 13, key: 13, text: '13'},
    {value: 14, key: 14, text: '14'},
    {value: 15, key: 15, text: '15'},
    {value: 16, key: 16, text: '16'},
    {value: 17, key: 17, text: '17'},
    {value: 18, key: 18, text: '18'},
    {value: 19, key: 19, text: '19'},
    {value: 20, key: 20, text: '20'},
    {value: 21, key: 21, text: '21'},
    {value: 22, key: 22, text: '22'},
    {value: 23, key: 23, text: '23'},
    {value: 24, key: 24, text: '24'},
    {value: 25, key: 25, text: '25'},
    {value: 26, key: 26, text: '26'},
    {value: 27, key: 27, text: '27'},
    {value: 28, key: 28, text: '28'},
    {value: 29, key: 29, text: '29'},
    {value: 30, key: 30, text: '30'},
  ];

  const kaynakLevelOptions = [
    {value: 1, key: 1, text: '1'},
    {value: 2, key: 2, text: '2'},
    {value: 3, key: 3, text: '3'},
    {value: 4, key: 4, text: '4'},
    {value: 5, key: 5, text: '5'},
    {value: 6, key: 6, text: '6'},
    {value: 7, key: 7, text: '7'},
    {value: 8, key: 8, text: '8'},
    {value: 9, key: 9, text: '9'},
  ];

  const speedOptions = [
    {
      value: 0,
      key: '3 ' + translations[appLanguage]['g125'] + translations[appLanguage]['g126'],
      text: '3 ' + translations[appLanguage]['g125'] + translations[appLanguage]['g126'],
    },
    {
      value: 1,
      key: '5 ' + translations[appLanguage]['g125'] + translations[appLanguage]['g126'],
      text: '5 ' + translations[appLanguage]['g125'] + translations[appLanguage]['g126'],
    },
    {
      value: 2,
      key: '1 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g126'],
      text: '1 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g126'],
    },
    {
      value: 3,
      key: '2 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g126'],
      text: '2 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g126'],
    },
    {
      value: 4,
      key: '4 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g126'],
      text: '4 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g126'],
    },
    {
      value: 5,
      key: '12 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g126'],
      text: '12 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g126'],
    },
    {
      value: 6,
      key: '24 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g126'],
      text: '24 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g126'],
    },

    {
      value: 7,
      key: '3 ' + translations[appLanguage]['g125'] + translations[appLanguage]['g127'],
      text: '3 ' + translations[appLanguage]['g125'] + translations[appLanguage]['g127'],
    },
    {
      value: 8,
      key: '5 ' + translations[appLanguage]['g125'] + translations[appLanguage]['g127'],
      text: '5 ' + translations[appLanguage]['g125'] + translations[appLanguage]['g127'],
    },
    {
      value: 9,
      key: '1 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g127'],
      text: '1 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g127'],
    },
    {
      value: 10,
      key: '2 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g127'],
      text: '2 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g127'],
    },
    {
      value: 11,
      key: '4 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g127'],
      text: '4 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g127'],
    },
    {
      value: 12,
      key: '12 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g127'],
      text: '12 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g127'],
    },
    {
      value: 13,
      key: '24 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g127'],
      text: '24 ' + translations[appLanguage]['g128'] + translations[appLanguage]['g127'],
    },
  ];

  useEffect(() => {
    if (!bot) return;
    setAutoShield(bot.autoShield || false);
    setAutoSoldier(bot.autoSoldier || false);
    setAutoSoldierCallback(bot.autoSoldierCallback || false);
    setCollectHero(bot.collectHero || false);
    setAutoKlanHelp(bot.autoKlanHelp || false);
    setAutoKlanDonate(bot.autoKlanDonate || false);
    setAutoEvent(bot.autoEvent || false);
    setCollectLoginBox(bot.collectLoginBox || false);
    setCollectDailyJobBoxes(bot.collectDailyJobBoxes || false);
    setCollectTimeBoxes(bot.collectTimeBoxes || false);
    setCollectVIPBoxes(bot.collectVIPBoxes || false);
    setAutoKari(bot.autoKari || false);
    setCollectKlanGift(bot.collectKlanGift || false);
    setCollectPray(bot.collectPray || false);
    setPrayType(bot.prayType || 0);
    setAutoHarabe(bot.autoHarabe || false);
    setAutoKazi(bot.autoKazi || false);
    setKislaMax(bot.kislaMax || 0);
    setRevirMax(bot.revirMax || 0);
    setKeresteMax(bot.keresteMax || 0);
    setTahilMax(bot.tahilMax || 0);
    setMadenMax(bot.madenMax || 0);
    setAutoSefer(bot.autoSefer || false);
    setIsSeferHard(bot.isSeferHard || false);
    setAutoSeferData(bot.autoSeferData || []);
    setPreEventResources(bot.preEventResources || []);
    setDestroySpeed(bot.destroySpeed || {});
    setAutoAsi(bot.autoAsi || false);
    setAutoAsiLevel(bot.autoAsiLevel || 1);
    setAsiHero(bot.asiHero || []);
    setDaily(bot.daily || false);
    setAutoPearlDonate(bot.autoPearlDonate || false);
    setAutoSwapBox(bot.autoSwapBox || false);
    setAutoTrapBuild(bot.autoTrapBuild || false);
    setTrapType(bot.trapType || 0);
    setKaynakTopla(bot.kaynakTopla || false);
    setKaynakLevel(bot.kaynakLevel || 0);
    setKaynakSoldierFill(bot.kaynakSoldierFill || 0);
    if (!bot.kaynakLevels || !(bot.kaynakLevels?.length > 0)) setKaynakLevels([bot.kaynakLevel || 0]);
    else setKaynakLevels(bot.kaynakLevels || [0]);

    if (bot?.kaynakData?.length > 4) {
      setKaynakData(bot.kaynakData);
    }

    const newSelectedAsiHeros = {};
    newSelectedAsiHeros[bot?._id] = {};
    newSelectedAsiHeros[bot?._id][(bot.asiHero || [])[0]] = true;
    newSelectedAsiHeros[bot?._id][(bot.asiHero || [])[1]] = true;
    newSelectedAsiHeros[bot?._id][(bot.asiHero || [])[2]] = true;
    newSelectedAsiHeros[bot?._id][(bot.asiHero || [])[3]] = true;
    newSelectedAsiHeros[bot?._id][(bot.asiHero || [])[4]] = true;
    setSelectedAsiHeros(newSelectedAsiHeros);

    const newSelectedHeros = {};
    newSelectedHeros[bot?._id] = {};
    for (let l = 0; l < 5; l++) {
      newSelectedHeros[bot?._id][(bot.kaynakData?.[l]?.hero || [])[0]] = true;
      newSelectedHeros[bot?._id][(bot.kaynakData?.[l]?.hero || [])[1]] = true;
      newSelectedHeros[bot?._id][(bot.kaynakData?.[l]?.hero || [])[2]] = true;
    }
    setSelectedHeros(newSelectedHeros);
  }, [bots, selectedIndex]);

  const renderSave = () => {
    if (changed)
      return (
        <div className={styles.saveButton}>
          <Button
            primary
            onClick={async () => {
              const newBot = {
                _id: bot._id,
                autoShield,
                autoSoldier,
                collectHero,
                autoKlanHelp,
                autoKlanDonate,
                autoEvent,
                collectLoginBox,
                collectDailyJobBoxes,
                collectTimeBoxes,
                collectVIPBoxes,
                autoKari,
                collectKlanGift,
                collectPray,
                prayType,
                autoHarabe,
                autoKazi,
                kislaMax,
                revirMax,
                keresteMax,
                tahilMax,
                madenMax,
                autoSefer,
                isSeferHard,
                autoSeferData,
                autoSoldierCallback,
                preEventResources,
                destroySpeed,
                asiHero,
                autoAsi,
                autoAsiLevel,
                daily,
                autoPearlDonate,
                autoSwapBox,
                autoTrapBuild,
                trapType,
                kaynakTopla,
                kaynakLevel,
                kaynakData,
                kaynakLevels,
                kaynakSoldierFill,
              };
              props.NM.info(translations[appLanguage]['g187'], '', 2000);
              await pushBot(newBot);
              // props.NM.info('Mail Gönderiliyor', '', 50000, null, false);
              props.NM.success(translations[appLanguage]['g188'], '', 2000);

              setChanged(false);
              let pageSettings = {tab: 1, index: selectedIndex};
              props.history.push('/panel' + JSON.stringify(pageSettings));
            }}
          >
            {translations[appLanguage]['g76']}
          </Button>
        </div>
      );
  };

  const renderKaynakData = () => {
    return kaynakData.map((el, i) => {
      return (
        <div className={el.enable ? styles.multiple_input_container : styles.input_container}>
          <Checkbox
            disabled={!bot}
            checked={el.enable || false}
            label={translations[appLanguage]['g' + (160 + i)] + ':'}
            onChange={(e, {checked}) => {
              setChanged(true);
              el.enable = checked;
              setKaynakData([...kaynakData]);
            }}
          />
          {el.enable && (
            <div>
              {translations[appLanguage]['g166'] + ' : '}
              <Select
                Dropdown
                value={el.tur || 0}
                onChange={(e, {value}) => {
                  el.tur = value;
                  setKaynakData([...kaynakData]);
                  setChanged(true);
                }}
                className={styles.prayTypeInput}
                options={kaynakOptions}
              />

              <div>
                {translations[appLanguage]['g135'] + ' : '}
                <div>
                  <Select
                    Dropdown
                    disabled={!bot}
                    value={el.hero?.[0] >= 0 ? el.hero?.[0] : -1}
                    onChange={(e, {value}) => {
                      if (!selectedHeros[bot?._id]) selectedHeros[bot?._id] = {};
                      if (!el.hero) el.hero = [];
                      selectedHeros[bot?._id][el.hero[0]] = false;
                      el.hero[0] = value;
                      selectedHeros[bot?._id][value] = true;
                      setSelectedHeros({...selectedHeros});
                      setKaynakData([...kaynakData]);
                      setChanged(true);
                    }}
                    className={styles.hero_container}
                    options={generateHeroOptions(el, 0)}
                  />
                  <Select
                    Dropdown
                    disabled={!bot}
                    value={el.hero?.[1] >= 0 ? el.hero?.[1] : -1}
                    onChange={(e, {value}) => {
                      if (!selectedHeros[bot?._id]) selectedHeros[bot?._id] = {};
                      if (!el.hero) el.hero = [];
                      selectedHeros[bot?._id][el.hero[1]] = false;
                      el.hero[1] = value;
                      selectedHeros[bot?._id][value] = true;
                      setSelectedHeros({...selectedHeros});
                      setKaynakData([...kaynakData]);
                      setChanged(true);
                    }}
                    className={styles.hero_container}
                    options={generateHeroOptions(el, 1)}
                  />
                  <Select
                    Dropdown
                    disabled={!bot}
                    value={el.hero?.[2] >= 0 ? el.hero?.[2] : -1}
                    onChange={(e, {value}) => {
                      if (!selectedHeros[bot?._id]) selectedHeros[bot?._id] = {};
                      if (!el.hero) el.hero = [];
                      selectedHeros[bot?._id][el.hero[2]] = false;
                      el.hero[2] = value;
                      selectedHeros[bot?._id][value] = true;
                      setSelectedHeros({...selectedHeros});
                      setKaynakData([...kaynakData]);
                      setChanged(true);
                    }}
                    className={styles.hero_container}
                    options={generateHeroOptions(el, 2)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const renderStartButton = () => {
    if (
      bot?.botType !== 1 &&
      showMilitaryButton &&
      bot?.started &&
      bot?.userStarted &&
      bot?.isActive &&
      bot?.idBufferReady &&
      !bot?.eventProgressing &&
      !bot?.startEventNow
    )
      return (
        <div style={{margin: '10px', textAlign: 'center'}}>
          <div
            onClick={() => {
              setShowMilitaryButton(false);
              pushBot({_id: bot._id, startEventNow: true}, true);
            }}
            className="ui button blue"
          >
            {translations[appLanguage]['g106']}
          </div>
        </div>
      );
  };

  const renderKaynakBoxes = () => {
    const returnThis = [];
    for (let a = 0; a < 9; a++) {
      returnThis.push(
        <div style={{display: 'flex'}}>
          <div>{a + 1}</div>
          <Checkbox
            checked={kaynakLevels.indexOf(a) >= 0}
            onChange={(e, {checked}) => {
              let levels = [...kaynakLevels];

              if (checked) {
                if (levels.length > 2) levels = levels.slice(1);
                levels = [...levels, a].sort((a, b) => b - a);
                setKaynakLevels(levels);
              } else {
                if (levels.length < 1) return;
                levels = levels.filter((el) => el !== a);
                levels = [...levels].sort((a, b) => b - a);
                setKaynakLevels(levels);
              }
              setChanged(true);
            }}
          />
        </div>
      );
    }
    return returnThis;
  };

  return (
    <div className={styles.setting_tab_container}>
      {renderStartButton()}
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={autoShield}
          label={translations[appLanguage]['g12']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setAutoShield(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={true}
          // checked={autoSoldierCallback}
          checked={false}
          label={translations[appLanguage]['g108']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setAutoSoldierCallback(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={true}
          checked={false}
          // checked={autoSoldier}
          label={translations[appLanguage]['g13']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setAutoSoldier(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={collectHero}
          label={translations[appLanguage]['g14']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setCollectHero(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={autoKlanHelp}
          label={translations[appLanguage]['g15']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setAutoKlanHelp(checked);
          }}
          className={styles.main_select}
        />
      </div>
      {bot?.botType !== 1 && (
        <div className={styles.input_container}>
          <Checkbox
            disabled={true}
            // checked={autoKlanDonate}
            checked={false}
            label={translations[appLanguage]['g16']}
            onChange={(e, {checked}) => {
              setChanged(true);
              setAutoKlanDonate(checked);
            }}
            className={styles.main_select}
          />
        </div>
      )}
      {bot?.botType !== 1 && (
        <div className={autoEvent ? styles.multiple_input_container : styles.input_container}>
          <Checkbox
            disabled={true}
            checked={false}
            // checked={autoEvent}
            label={translations[appLanguage]['g17']}
            onChange={(e, {checked}) => {
              setChanged(true);
              setAutoEvent(checked);
            }}
            className={styles.main_select}
          />

          {autoEvent && (
            <div className={styles.sub_container}>
              <div>{translations[appLanguage]['g109']}</div>

              <div>
                {translations[appLanguage]['g41'] + ':'}
                <Select
                  Dropdown
                  selection
                  clearable={true}
                  disabled={!bot}
                  value={preEventResources[0]?.type || ''}
                  onChange={(e, {value}) => {
                    setPreEventResources([{...preEventResources[0], type: value}, preEventResources[1]]);
                    setChanged(true);
                  }}
                  className={styles.prayTypeInput}
                  placeholder={translations[appLanguage]['g110']}
                  options={packageOptions}
                />
                {preEventResources[0]?.type >= 0 && (
                  <>
                    {translations[appLanguage]['g111'] + ':'}
                    <div className={'input small ui ' + styles.settings_input}>
                      <input
                        type="text"
                        disabled={!bot}
                        placeholder={translations[appLanguage]['g111']}
                        value={preEventResources[0]?.count || 0}
                        onChange={({target: {value}}) => {
                          setChanged(true);
                          if (value) value = parseInt(value);
                          if (isNaN(value)) value = 0;
                          if (!value) value = 0;
                          setPreEventResources([{...preEventResources[0], count: value}, preEventResources[1]]);
                        }}
                        //   onBlur={() => {
                        //     if (parseInt(preEventResources[0].count) >= 0) {
                        //     } else setKislaMax(0);
                        //   }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div>
                {translations[appLanguage]['g41'] + ':'}
                <Select
                  Dropdown
                  selection
                  clearable={true}
                  disabled={!bot}
                  value={preEventResources[1]?.type || ''}
                  onChange={(e, {value}) => {
                    setPreEventResources([preEventResources[0], {...preEventResources[1], type: value}]);
                    setChanged(true);
                  }}
                  className={styles.prayTypeInput}
                  placeholder={translations[appLanguage]['g110']}
                  options={packageOptions}
                />
                {preEventResources[1]?.type >= 0 && (
                  <>
                    {translations[appLanguage]['g111'] + ':'}
                    <div className={'input small ui ' + styles.settings_input}>
                      <input
                        type="text"
                        placeholder={translations[appLanguage]['g111']}
                        disabled={!bot}
                        value={preEventResources[1]?.count || 0}
                        onChange={({target: {value}}) => {
                          setChanged(true);
                          if (value) value = parseInt(value);
                          if (isNaN(value)) value = 0;
                          if (!value) value = 0;
                          setPreEventResources([preEventResources[0], {...preEventResources[1], count: value}]);
                        }}
                        //   onBlur={() => {
                        //     if (parseInt(preEventResources[0].count) >= 0) {
                        //     } else setKislaMax(0);
                        //   }}
                      />
                    </div>{' '}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={collectLoginBox}
          label={translations[appLanguage]['g18']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setCollectLoginBox(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={collectDailyJobBoxes}
          label={translations[appLanguage]['g19']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setCollectDailyJobBoxes(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={collectTimeBoxes}
          label={translations[appLanguage]['g20']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setCollectTimeBoxes(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={collectVIPBoxes}
          label={translations[appLanguage]['g21']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setCollectVIPBoxes(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={autoKari}
          label={translations[appLanguage]['g64']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setAutoKari(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={collectKlanGift}
          label={translations[appLanguage]['g65']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setCollectKlanGift(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={collectPray ? styles.multiple_input_container : styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={collectPray}
          label={translations[appLanguage]['g66']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setCollectPray(checked);
          }}
          className={styles.main_select}
        />

        {collectPray && (
          <Select
            disabled={!bot}
            value={prayType}
            onChange={(e, {value}) => {
              setPrayType(value);
              setChanged(true);
            }}
            className={styles.prayTypeInput}
            placeholder={translations[appLanguage]['g71']}
            options={[
              {key: translations[appLanguage]['g70'], text: translations[appLanguage]['g70'], value: 0},
              {key: translations[appLanguage]['g69'], text: translations[appLanguage]['g69'], value: 1},
              {key: translations[appLanguage]['g71'], text: translations[appLanguage]['g71'], value: 2},
            ]}
          />
        )}
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={autoHarabe}
          label={translations[appLanguage]['g107']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setAutoHarabe(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={autoKazi}
          label={translations[appLanguage]['g105']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setAutoKazi(checked);
          }}
          className={styles.main_select}
        />
      </div>
      <div className={autoSefer ? styles.multiple_input_container : styles.input_container}>
        <Checkbox
          disabled={!bot}
          checked={autoSefer}
          label={translations[appLanguage]['g103']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setAutoSefer(checked);
          }}
          className={styles.main_select}
        />

        {autoSefer && (
          <div>
            <Checkbox
              disabled={!bot}
              checked={isSeferHard}
              label={translations[appLanguage]['g173']}
              onChange={(e, {checked}) => {
                setChanged(true);
                setIsSeferHard(checked);
              }}
              className={styles.main_select}
            />
            <Select
              Dropdown
              selection
              clearable={true}
              disabled={!bot}
              value={autoSeferData[0]}
              onChange={(e, {value}) => {
                setAutoSeferData([value, autoSeferData[1]]);
                setChanged(true);
              }}
              className={styles.prayTypeInput}
              placeholder={''}
              options={seferOptions}
            />
            <Select
              Dropdown
              selection
              clearable={true}
              disabled={!bot}
              value={autoSeferData[1]}
              onChange={(e, {value}) => {
                setAutoSeferData([autoSeferData[0], value]);
                setChanged(true);
              }}
              className={styles.prayTypeInput}
              placeholder={''}
              options={seferOptions}
            />
          </div>
        )}
      </div>
      <div className={autoAsi ? styles.multiple_input_container : styles.input_container}>
        <Checkbox
          disabled={true}
          checked={false}
          // checked={autoAsi}
          label={translations[appLanguage]['g104']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setAutoAsi(checked);
          }}
          className={styles.main_select}
        />
        {/* {autoAsi && ( */}
        {false && (
          <div className={styles.sub_container}>
            <div>
              {translations[appLanguage]['g136'] + ':'}
              <Select
                Dropdown
                selection
                disabled={!bot}
                value={autoAsiLevel}
                onChange={(e, {value}) => {
                  setAutoAsiLevel(value);
                  setChanged(true);
                }}
                className={styles.prayTypeInput}
                placeholder={translations[appLanguage]['g110']}
                options={asiLevelOptions}
              />
            </div>
            <div>
              {translations[appLanguage]['g135'] + ' 1 :'}
              <Select
                Dropdown
                selection
                clearable={true}
                disabled={!bot}
                value={asiHero?.[0]}
                onChange={(e, {value}) => {
                  if (!selectedAsiHeros[bot?._id]) selectedAsiHeros[bot?._id] = {};
                  selectedAsiHeros[bot?._id][asiHero[0]] = false;
                  asiHero[0] = value;
                  selectedAsiHeros[bot?._id][value] = true;
                  setSelectedAsiHeros({...selectedAsiHeros});

                  setAsiHero([...asiHero]);
                  setChanged(true);
                }}
                className={styles.prayTypeInput}
                placeholder={translations[appLanguage]['g135']}
                options={generateAsiHeroOptions(asiHero, 0)}
              />
            </div>
            <div>
              {translations[appLanguage]['g135'] + ' 2 :'}
              <Select
                Dropdown
                selection
                clearable={true}
                disabled={!bot}
                value={asiHero?.[1]}
                onChange={(e, {value}) => {
                  if (!selectedAsiHeros[bot?._id]) selectedAsiHeros[bot?._id] = {};
                  selectedAsiHeros[bot?._id][asiHero[1]] = false;
                  asiHero[1] = value;
                  selectedAsiHeros[bot?._id][value] = true;
                  setSelectedAsiHeros({...selectedAsiHeros});
                  setAsiHero([...asiHero]);
                  setChanged(true);
                }}
                className={styles.prayTypeInput}
                placeholder={translations[appLanguage]['g135']}
                options={generateAsiHeroOptions(asiHero, 1)}
              />
            </div>
            <div>
              {translations[appLanguage]['g135'] + ' 3 :'}
              <Select
                Dropdown
                selection
                clearable={true}
                disabled={!bot}
                value={asiHero?.[2]}
                onChange={(e, {value}) => {
                  if (!selectedAsiHeros[bot?._id]) selectedAsiHeros[bot?._id] = {};
                  selectedAsiHeros[bot?._id][asiHero[2]] = false;
                  asiHero[2] = value;
                  selectedAsiHeros[bot?._id][value] = true;
                  setSelectedAsiHeros({...selectedAsiHeros});
                  setAsiHero([...asiHero]);
                  setChanged(true);
                }}
                className={styles.prayTypeInput}
                placeholder={translations[appLanguage]['g135']}
                options={generateAsiHeroOptions(asiHero, 2)}
              />
            </div>
            <div>
              {translations[appLanguage]['g135'] + ' 4 :'}
              <Select
                Dropdown
                selection
                clearable={true}
                disabled={!bot}
                value={asiHero?.[3]}
                onChange={(e, {value}) => {
                  if (!selectedAsiHeros[bot?._id]) selectedAsiHeros[bot?._id] = {};
                  selectedAsiHeros[bot?._id][asiHero[3]] = false;
                  asiHero[3] = value;
                  selectedAsiHeros[bot?._id][value] = true;
                  setSelectedAsiHeros({...selectedAsiHeros});
                  setAsiHero([...asiHero]);

                  setChanged(true);
                }}
                className={styles.prayTypeInput}
                placeholder={translations[appLanguage]['g135']}
                options={generateAsiHeroOptions(asiHero, 3)}
              />
            </div>
            <div>
              {translations[appLanguage]['g135'] + ' 5 :'}
              <Select
                Dropdown
                selection
                clearable={true}
                disabled={!bot}
                value={asiHero?.[4]}
                onChange={(e, {value}) => {
                  if (!selectedAsiHeros[bot?._id]) selectedAsiHeros[bot?._id] = {};
                  selectedAsiHeros[bot?._id][asiHero[4]] = false;
                  asiHero[4] = value;
                  selectedAsiHeros[bot?._id][value] = true;
                  setSelectedAsiHeros({...selectedAsiHeros});
                  setAsiHero([...asiHero]);
                  setChanged(true);
                }}
                className={styles.prayTypeInput}
                placeholder={translations[appLanguage]['g135']}
                options={generateAsiHeroOptions(asiHero, 4)}
              />
            </div>
          </div>
        )}
      </div>
      <div className={daily ? styles.multiple_input_container : styles.input_container}>
        <Checkbox
          disabled={true}
          // checked={daily}
          checked={false}
          label={translations[appLanguage]['g151']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setDaily(checked);
          }}
          className={styles.main_select}
        />

        {/* {daily && ( */}
        {false && (
          <>
            <div className={styles.sub_container}>
              <Checkbox
                disabled={!bot}
                checked={autoPearlDonate}
                label={translations[appLanguage]['g152']}
                onChange={(e, {checked}) => {
                  setChanged(true);
                  setAutoPearlDonate(checked);
                }}
              />
            </div>
            <div className={styles.sub_container}>
              <Checkbox
                disabled={!bot}
                checked={autoSwapBox}
                label={translations[appLanguage]['g153']}
                onChange={(e, {checked}) => {
                  setChanged(true);
                  setAutoSwapBox(checked);
                }}
              />
            </div>

            <div className={styles.sub_container}>
              <Checkbox
                disabled={!bot}
                checked={autoTrapBuild}
                label={translations[appLanguage]['g154']}
                onChange={(e, {checked}) => {
                  setChanged(true);
                  setAutoTrapBuild(checked);
                }}
              />
              {autoTrapBuild && (
                <Select
                  Dropdown
                  selection
                  disabled={!bot}
                  value={trapType}
                  onChange={(e, {value}) => {
                    setTrapType(value);
                    setChanged(true);
                  }}
                  className={styles.prayTypeInput}
                  placeholder={''}
                  options={tuzakOptions}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className={kaynakTopla ? styles.multiple_input_container : styles.input_container}>
        <Checkbox
          disabled={true}
          // checked={kaynakTopla}
          checked={false}
          label={translations[appLanguage]['g159']}
          onChange={(e, {checked}) => {
            setChanged(true);
            setKaynakTopla(checked);
          }}
          className={styles.main_select}
        />
        {/* {kaynakTopla && ( */}
        {false && (
          <div className={styles.sub_container}>
            <Checkbox
              disabled={!bot}
              checked={kaynakSoldierFill}
              label={translations[appLanguage]['g171']}
              onChange={(e, {checked}) => {
                setChanged(true);
                setKaynakSoldierFill(checked);
              }}
              // className={styles.main_select}
            />
          </div>
        )}
        {/* {kaynakTopla && ( */}
        {false && (
          <div className={styles.sub_container}>
            <span>{translations[appLanguage]['g167']}</span>
            <span style={{fontSize: 'x-small'}}>{' ' + translations[appLanguage]['g170'] + ':'}</span>

            <div className={styles.kaynakCheckBoxes}>{renderKaynakBoxes()}</div>
            {renderKaynakData()}
          </div>
        )}
      </div>
      {/* {bot?.botType !== 1 && ( */}
      {bot?.botType !== 1 && false && (
        <div>
          <div className={styles.input_container}>
            <div className={styles.settings_text}>{translations[appLanguage]['g22']} </div>
            <div className={'input small ui ' + styles.settings_input}>
              <input
                type="text"
                disabled={!bot}
                value={kislaMax}
                onChange={({target: {value}}) => {
                  setChanged(true);
                  setKislaMax(value);
                }}
                onBlur={() => {
                  if (parseInt(kislaMax) >= 0) {
                    if (parseInt(kislaMax) > 20) setKislaMax(20);
                  } else setKislaMax(0);
                }}
              />
            </div>
          </div>
          <div className={destroySpeed?.kisla?.enable ? styles.multiple_input_container : styles.input_container}>
            <Checkbox
              disabled={!bot}
              checked={destroySpeed?.kisla?.enable || false}
              label={translations[appLanguage]['g120']}
              onChange={(e, {checked}) => {
                setChanged(true);
                let setData = destroySpeed || {};
                if (!setData?.kisla) setData.kisla = {};
                setData.kisla.enable = checked;
                if (!setData?.kisla?.type) setData.kisla.type = 0;
                if (!setData?.kisla?.count) setData.kisla.count = 1;

                setDestroySpeed({...setData});
              }}
            />
            {destroySpeed?.kisla?.enable && (
              <div className={styles.destroy_input_container}>
                <div>
                  {translations[appLanguage]['g41'] + ':'}
                  <Select
                    Dropdown
                    selection
                    disabled={!bot}
                    value={destroySpeed?.kisla?.type || 0}
                    onChange={(e, {value}) => {
                      const setData = destroySpeed || {};
                      if (!setData?.kisla) setData.kisla = {};
                      setData.kisla.type = value;
                      setDestroySpeed({...setData});
                      setChanged(true);
                    }}
                    className={styles.prayTypeInput}
                    placeholder={translations[appLanguage]['g110']}
                    options={speedOptions}
                  />
                </div>

                <div>
                  {translations[appLanguage]['g111'] + ':'}
                  <div className={'input small ui ' + styles.settings_input}>
                    <input
                      type="text"
                      disabled={!bot}
                      placeholder={translations[appLanguage]['g111']}
                      value={destroySpeed?.kisla?.count || 0}
                      onChange={({target: {value}}) => {
                        setChanged(true);
                        if (value) value = parseInt(value);
                        if (isNaN(value)) value = 0;
                        if (!value) value = 0;
                        let setData = destroySpeed || {};
                        if (!setData?.kisla) setData.kisla = {};
                        setData.kisla.count = value;
                        setDestroySpeed({...setData});
                        setChanged(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.input_container}>
            <div className={styles.settings_text}>{translations[appLanguage]['g23']} </div>
            <div className={'input small ui ' + styles.settings_input}>
              <input
                type="text"
                disabled={!bot}
                value={revirMax}
                onChange={({target: {value}}) => {
                  setChanged(true);
                  setRevirMax(value);
                }}
                onBlur={() => {
                  if (parseInt(revirMax) >= 0) {
                    if (parseInt(revirMax) > 20) setRevirMax(20);
                  } else revirMax(0);
                }}
              />
            </div>
          </div>
          <div className={destroySpeed?.revir?.enable ? styles.multiple_input_container : styles.input_container}>
            <Checkbox
              disabled={!bot}
              checked={destroySpeed?.revir?.enable || false}
              label={translations[appLanguage]['g122']}
              onChange={(e, {checked}) => {
                setChanged(true);
                let setData = destroySpeed || {};
                if (!setData?.revir) setData.revir = {};
                setData.revir.enable = checked;
                if (!setData?.revir?.type) setData.revir.type = 0;
                if (!setData?.revir?.count) setData.revir.count = 1;
                setDestroySpeed({...setData});
              }}
            />
            {destroySpeed?.revir?.enable && (
              <div className={styles.destroy_input_container}>
                <div>
                  {translations[appLanguage]['g41'] + ':'}
                  <Select
                    Dropdown
                    selection
                    disabled={!bot}
                    value={destroySpeed?.revir?.type || 0}
                    onChange={(e, {value}) => {
                      const setData = destroySpeed || {};
                      if (!setData?.revir) setData.revir = {};
                      setData.revir.type = value;
                      setDestroySpeed({...setData});
                      setChanged(true);
                    }}
                    className={styles.prayTypeInput}
                    placeholder={translations[appLanguage]['g110']}
                    options={speedOptions}
                  />
                </div>

                {destroySpeed?.revir?.type >= 0 && (
                  <div>
                    {translations[appLanguage]['g111'] + ':'}
                    <div className={'input small ui ' + styles.settings_input}>
                      <input
                        type="text"
                        disabled={!bot}
                        placeholder={translations[appLanguage]['g111']}
                        value={destroySpeed?.revir?.count || 0}
                        onChange={({target: {value}}) => {
                          setChanged(true);
                          if (value) value = parseInt(value);
                          if (isNaN(value)) value = 0;
                          if (!value) value = 0;
                          let setData = destroySpeed || {};
                          if (!setData?.revir) setData.revir = {};
                          setData.revir.count = value;
                          setDestroySpeed({...setData});
                          setChanged(true);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={styles.input_container}>
            <div className={styles.settings_text}>{translations[appLanguage]['g24']} </div>
            <div className={'input small ui ' + styles.settings_input}>
              <input
                type="text"
                disabled={!bot}
                value={keresteMax}
                onChange={({target: {value}}) => {
                  setChanged(true);
                  setKeresteMax(value);
                }}
                onBlur={() => {
                  if (parseInt(keresteMax) >= 0) {
                    if (parseInt(keresteMax) > 20) setKeresteMax(20);
                  } else setKeresteMax(0);
                }}
              />
            </div>
          </div>
          <div className={destroySpeed?.kereste?.enable ? styles.multiple_input_container : styles.input_container}>
            <Checkbox
              disabled={!bot}
              checked={destroySpeed?.kereste?.enable || false}
              label={translations[appLanguage]['g123']}
              onChange={(e, {checked}) => {
                setChanged(true);
                let setData = destroySpeed || {};
                if (!setData?.kereste) setData.kereste = {};
                setData.kereste.enable = checked;
                if (!setData?.kereste?.type) setData.kereste.type = 0;
                if (!setData?.kereste?.count) setData.kereste.count = 1;
                setDestroySpeed({...setData});
              }}
            />
            {destroySpeed?.kereste?.enable && (
              <div className={styles.destroy_input_container}>
                <div>
                  {translations[appLanguage]['g41'] + ':'}
                  <Select
                    Dropdown
                    selection
                    disabled={!bot}
                    value={destroySpeed?.kereste?.type || 0}
                    onChange={(e, {value}) => {
                      const setData = destroySpeed || {};
                      if (!setData?.kereste) setData.kereste = {};
                      setData.kereste.type = value;
                      setDestroySpeed({...setData});
                      setChanged(true);
                    }}
                    className={styles.prayTypeInput}
                    placeholder={translations[appLanguage]['g110']}
                    options={speedOptions}
                  />
                </div>

                {destroySpeed?.kereste?.type >= 0 && (
                  <div>
                    {translations[appLanguage]['g111'] + ':'}
                    <div className={'input small ui ' + styles.settings_input}>
                      <input
                        type="text"
                        disabled={!bot}
                        placeholder={translations[appLanguage]['g111']}
                        value={destroySpeed?.kereste?.count || 0}
                        onChange={({target: {value}}) => {
                          setChanged(true);
                          if (value) value = parseInt(value);
                          if (isNaN(value)) value = 0;
                          if (!value) value = 0;
                          let setData = destroySpeed || {};
                          if (!setData?.kereste) setData.kereste = {};
                          setData.kereste.count = value;
                          setDestroySpeed({...setData});
                          setChanged(true);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={styles.input_container}>
            <div className={styles.settings_text}>{translations[appLanguage]['g25']} </div>
            <div className={'input small ui ' + styles.settings_input}>
              <input
                type="text"
                disabled={!bot}
                value={tahilMax}
                onChange={({target: {value}}) => {
                  setChanged(true);
                  setTahilMax(value);
                }}
                onBlur={() => {
                  if (parseInt(tahilMax) >= 0) {
                    if (parseInt(tahilMax) > 20) setTahilMax(20);
                  } else setTahilMax(0);
                }}
              />
            </div>
          </div>
          <div className={destroySpeed?.tahil?.enable ? styles.multiple_input_container : styles.input_container}>
            <Checkbox
              disabled={!bot}
              checked={destroySpeed?.tahil?.enable || false}
              label={translations[appLanguage]['g124']}
              onChange={(e, {checked}) => {
                setChanged(true);
                let setData = destroySpeed || {};
                if (!setData?.tahil) setData.tahil = {};
                setData.tahil.enable = checked;
                if (!setData?.tahil?.type) setData.tahil.type = 0;
                if (!setData?.tahil?.count) setData.tahil.count = 1;
                setDestroySpeed({...setData});
              }}
            />
            {destroySpeed?.tahil?.enable && (
              <div className={styles.destroy_input_container}>
                <div>
                  {translations[appLanguage]['g41'] + ':'}
                  <Select
                    Dropdown
                    selection
                    disabled={!bot}
                    value={destroySpeed?.tahil?.type || 0}
                    onChange={(e, {value}) => {
                      const setData = destroySpeed || {};
                      if (!setData?.tahil) setData.tahil = {};
                      setData.tahil.type = value;
                      setDestroySpeed({...setData});
                      setChanged(true);
                    }}
                    className={styles.prayTypeInput}
                    placeholder={translations[appLanguage]['g110']}
                    options={speedOptions}
                  />
                </div>
                {destroySpeed?.tahil?.type >= 0 && (
                  <div>
                    {translations[appLanguage]['g111'] + ':'}
                    <div className={'input small ui ' + styles.settings_input}>
                      <input
                        type="text"
                        disabled={!bot}
                        placeholder={translations[appLanguage]['g111']}
                        value={destroySpeed?.tahil?.count || 0}
                        onChange={({target: {value}}) => {
                          setChanged(true);
                          if (value) value = parseInt(value);
                          if (isNaN(value)) value = 0;
                          if (!value) value = 0;
                          let setData = destroySpeed || {};
                          if (!setData?.tahil) setData.tahil = {};
                          setData.tahil.count = value;
                          setDestroySpeed({...setData});
                          setChanged(true);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={styles.input_container}>
            <div className={styles.settings_text}>{translations[appLanguage]['g26']} </div>
            <div className={'input small ui ' + styles.settings_input}>
              <input
                type="text"
                disabled={!bot}
                value={madenMax}
                onChange={({target: {value}}) => {
                  setChanged(true);
                  setMadenMax(value);
                }}
                onBlur={() => {
                  if (parseInt(madenMax) >= 0) {
                    if (parseInt(madenMax) > 20) setMadenMax(20);
                  } else setMadenMax(0);
                }}
              />
            </div>
          </div>
          <div
            className={destroySpeed?.maden?.enable ? styles.multiple_input_container_last : styles.input_container_last}
          >
            <Checkbox
              disabled={!bot}
              checked={destroySpeed?.maden?.enable || false}
              label={translations[appLanguage]['g121']}
              onChange={(e, {checked}) => {
                setChanged(true);
                let setData = destroySpeed || {};
                if (!setData?.maden) setData.maden = {};
                setData.maden.enable = checked;
                if (!setData?.maden?.type) setData.maden.type = 0;
                if (!setData?.maden?.count) setData.maden.count = 1;
                setDestroySpeed({...setData});
              }}
            />
            {destroySpeed?.maden?.enable && (
              <div className={styles.destroy_input_container}>
                <div>
                  {translations[appLanguage]['g41'] + ':'}
                  <Select
                    Dropdown
                    selection
                    disabled={!bot}
                    value={destroySpeed?.maden?.type || 0}
                    onChange={(e, {value}) => {
                      const setData = destroySpeed || {};
                      if (!setData?.maden) setData.maden = {};
                      setData.maden.type = value;
                      setDestroySpeed({...setData});
                      setChanged(true);
                    }}
                    className={styles.prayTypeInput}
                    placeholder={translations[appLanguage]['g110']}
                    options={speedOptions}
                  />
                </div>
                {destroySpeed?.maden?.type >= 0 && (
                  <div>
                    {translations[appLanguage]['g111'] + ':'}
                    <div className={'input small ui ' + styles.settings_input}>
                      <input
                        type="text"
                        disabled={!bot}
                        placeholder={translations[appLanguage]['g111']}
                        value={destroySpeed?.maden?.count || 0}
                        onChange={({target: {value}}) => {
                          setChanged(true);
                          if (value) value = parseInt(value);
                          if (isNaN(value)) value = 0;
                          if (!value) value = 0;
                          let setData = destroySpeed || {};
                          if (!setData?.maden) setData.maden = {};
                          setData.maden.count = value;
                          setDestroySpeed({...setData});
                          setChanged(true);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {renderSave()}
    </div>
  );
}
