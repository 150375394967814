import {SET_ERROR, SET_LOADING} from 'context/shared';
import {apiURL, axiosClient} from 'service';
import {SET_USER, RESET_USER} from 'context/user/keys';

export const getUser = (dispatch) => async () => {
  dispatch({type: SET_LOADING});
  const {data} = await axiosClient.get(apiURL.user, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });
  if (data.email) {
    dispatch({type: SET_USER, payload: data});
  }
};

export const pushUser = (dispatch) => async (user) => {
  dispatch({type: SET_LOADING});
  const {data} = await axiosClient.put(apiURL.user, user, {
    headers: {
      Authorization: 'ARGA ' + localStorage.getItem('token'),
    },
  });
  if (data.email) {
    dispatch({type: SET_USER, payload: data});
  }
};

export const resetUser = (dispatch) => async () => {
  dispatch({type: RESET_USER});
};
