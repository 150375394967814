import React, {useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Select} from 'semantic-ui-react';

import {translations} from 'resources';
import {LanguageContext} from 'context';
import ayyildiz_logo from 'resources/images/ayyildiz_logo.png';
import styles from './onboarding.module.css';

const languageOptions = [
  {key: 'Türkçe', value: 'tr', text: 'Türkçe'},
  {key: 'English', value: 'en', text: 'English'},
  {key: 'العربية', value: 'arb', text: 'العربية'},
];

export default function Onboarding(props) {
  const {
    state: {appLanguage},
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    onboarding_container,
    content_container,
    app_name,
    signup_text_container,
    join_text,
    signup_text,
    signin_text_container,
    already_member_text,
    signin_text,
    seperator,
  } = styles;


  return (
    <div className={onboarding_container}>
      <div className={content_container}>
        <img className={styles.logo} src={ayyildiz_logo} alt="ayyildiz_logo" />

        <div className={signup_text_container}>
          <div className={join_text}>{translations[appLanguage]['g29']}</div>
          <Link to="/signup" className={signup_text}>
            {translations[appLanguage]['g30']}
          </Link>
        </div>

        <div className={signin_text_container}>
          <div className={already_member_text}>{translations[appLanguage]['g28']}</div>
          <Link to="/signin" className={signin_text}>
            {translations[appLanguage]['g6']}
          </Link>
          <div className={seperator} />
          <Select
            value={appLanguage}
            onChange={(e, {value}) => changeAppLanguage(value)}
            className={styles.select}
            placeholder="Türkçe"
            options={languageOptions}
          />
        </div>
      </div>
    </div>
  );
}
