import React, {useState, useContext, useEffect} from 'react';
import {translations} from 'resources';
import {LanguageContext, UserContext} from 'context';
import styles from './moderator-bots.module.css';
import TableSearchBar from '../../components/tableSearchBar';
import PageHeader from '../../components/pageHeader';
import {parsePageSettings} from '../../../helpers/page';

import {apiURL, axiosClient} from 'service';

export default function BotPage(props) {
  let filter = {
    sort: '-createdOn',
    pages: {
      size: 2000,
      number: 0,
    },
    select: {
      iggID: 1,
      // gameNick: 1,
      // ownerEmail: 1,
      // email: 1,
      // createdOn: 1,
      // finishDate: 1,
      // server: 1,
      // passWord: 1,
      // idBufferReady: 1,
      // started: 1,
      // isActive: 1,
      // wrongPassWord: 1,
      // shortid: 1,
    },
  };

  const [smallScreen, setSmallScreen] = useState(false);
  const [botList, setBotList] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchText, setSearchText] = useState('');
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {user},
  } = useContext(UserContext);

  const onResize = () => {
    if (window.innerWidth < 800) {
      setSmallScreen(true);
    } else setSmallScreen(false);
  };

  const onEditClick = (bot) => {
    let pageSettings = {id: bot._id || ''};
    props.history.push('/moderator/botEdit' + JSON.stringify(pageSettings));
  };

  const getBots = async () => {
    const response = await axiosClient.get(apiURL.modBots, {
      headers: {
        Authorization: 'ARGA ' + localStorage.getItem('token'),
      },
    });
    if (response?.data) setBotList(response.data);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    getBots();
  }, []);

  useEffect(() => {
    if (searchText) {
      const newFiltered = botList.filter((el) => {
        const keys = Object.keys(el);
        for (let a = 0; a < keys.length; a++) {
          if (('' + el[keys[a]]).search(searchText) >= 0) {
            return true;
          }
        }
        return false;
      });
      setFiltered(newFiltered);
    } else setFiltered(botList);
  }, [botList, searchText]);

  const renderHead = () => {
    if (!smallScreen) {
      return (
        <thead>
          <tr>
            <th colSpan="1">#</th>
            <th colSpan="1">{translations[appLanguage]['g33']}</th>
            <th colSpan="1">{translations[appLanguage]['g85']}</th>
            <th colSpan="1">{translations[appLanguage]['g86']}</th>
            <th colSpan="1">{translations[appLanguage]['g87']}</th>
            <th colSpan="1">{'iggId'}</th>
            <th colSpan="1">{translations[appLanguage]['g83']}</th>
            <th colSpan="1">{translations[appLanguage]['g50']}</th>
            <th colSpan="1">{translations[appLanguage]['g88']}</th>
            <th colSpan="1"> </th>
          </tr>
        </thead>
      );
    }
  };

  const statusTextGenerator = (bot, user) => {
    if (bot.isActive) {
      if (bot.started) return translations[appLanguage]['g77'];
      else return translations[appLanguage]['g89'];
    } else if (bot.wrongPassWord) return translations[appLanguage]['g82'];
    else if (!bot.passWord || !bot.email) return translations[appLanguage]['g90'];
    else if (!user?.moderatorName) return 'Moderator needed';
    else if (!bot.idBufferReady) return 'IDBuffer';
    else return 'Passive';
  };

  const renderList = () => {
    return filtered
      ?.sort((a, b) => Date.parse(a.createdOn) - Date.parse(b.createdOn))
      ?.map?.((bot, i) => {
        return (
          <tr key={bot._id}>
            <td>{(smallScreen ? '# ' : '') + (1 + i)}</td>

            <td style={{textTransform: 'capitalize'}}>
              {(smallScreen ? translations[appLanguage]['g33'] + ' ' : '') + bot.server}
            </td>
            {/* <td>{(smallScreen ? "Soyisim: " : "") + work.customer.lastName}</td> */}
            <td>{(smallScreen ? translations[appLanguage]['g85'] + ' ' : '') + bot.gameNick}</td>
            <td>{(smallScreen ? translations[appLanguage]['g86'] + ' ' : '') + bot.ownerEmail}</td>
            <td>{(smallScreen ? translations[appLanguage]['g87'] + ' ' : '') + bot.email}</td>
            <td>{(smallScreen ? translations[appLanguage]['g88'] + ' ' : '') + bot.iggID}</td>
            <td>{(smallScreen ? translations[appLanguage]['g87'] + ' ' : '') + statusTextGenerator(bot, user)}</td>
            <td>
              {(smallScreen ? translations[appLanguage]['g50'] + ' ' : '') + new Date(bot.createdOn).toLocaleString()}
            </td>
            <td>
              {(smallScreen ? translations[appLanguage]['g88'] + ' ' : '') + new Date(bot.finishDate).toLocaleString()}
            </td>
            <td className={styles.iconLabelContainer}>
              <div className={styles.iconContainer}>
                <div>
                  <button
                    className="ui button icon blue"
                    icon
                    onClick={() => {
                      onEditClick(bot);
                    }}
                  >
                    <i style={{color: 'white'}} className="pencil alternate icon large" />
                  </button>
                </div>

                {/* <div>
                  <button className="ui button icon red" icon onClick={() => deleteBot(bot._id)}>
                    <i style={{color: 'white'}} className="trash icon large" />
                  </button>
                </div> */}
              </div>
            </td>
          </tr>
        );
      });
  };

  return (
    <PageHeader history={props.history} pageName={translations[appLanguage]['g84']}>
      <div className={styles.listContainer}>
        <div className={styles.searchContainer}>
          <div className={'ui left icon input'}>
            <input
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search ..."
              style={{color: 'rgb(0, 0, 0)', backgroundColor: 'rgb(255, 255, 255)', opacity: 1}}
            />
            <i className="users icon"></i>
          </div>
        </div>

        <div className={styles.listTableContainer}>
          <table className="table-container ui celled striped table">
            {renderHead()}
            <tbody>{renderList()}</tbody>
          </table>
        </div>
      </div>
    </PageHeader>
  );
}
