import React, {useContext} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {translations} from 'resources';
import {LanguageContext, BotContext} from 'context';

import './selectbox.css';

const SelectBox = ({top, left, placeIndex, disabled, NM}) => {
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {selectedIndex, bots},
    pushBot,
  } = useContext(BotContext);

  const bot = bots?.[selectedIndex];

  const farmOptions = [
    {
      key: translations[appLanguage]['g68'],
      text: translations[appLanguage]['g68'],
      value: 4,
    },
    {
      key: translations[appLanguage]['g67'],
      text: translations[appLanguage]['g67'],
      value: 3,
    },
    {
      key: translations[appLanguage]['g69'],
      text: translations[appLanguage]['g69'],
      value: 0,
    },
    {
      key: translations[appLanguage]['g70'],
      text: translations[appLanguage]['g70'],
      value: 1,
    },
    {
      key: translations[appLanguage]['g71'],
      text: translations[appLanguage]['g71'],
      value: 2,
    },
  ];

  const gameOptions = [
    {
      key: translations[appLanguage]['g68'],
      text: translations[appLanguage]['g68'],
      value: 4,
    },
    {
      key: translations[appLanguage]['g67'],
      text: translations[appLanguage]['g67'],
      value: 3,
    },
    {
      key: translations[appLanguage]['g69'],
      text: translations[appLanguage]['g69'],
      value: 0,
    },
    {
      key: translations[appLanguage]['g70'],
      text: translations[appLanguage]['g70'],
      value: 1,
    },
    {
      key: translations[appLanguage]['g71'],
      text: translations[appLanguage]['g71'],
      value: 2,
    },
    {
      key: translations[appLanguage]['g100'] + ' ' + translations[appLanguage]['g69'],
      text: translations[appLanguage]['g100'] + ' ' + translations[appLanguage]['g69'],
      value: -1,
    },
    {
      key: translations[appLanguage]['g100'] + ' ' + translations[appLanguage]['g70'],
      text: translations[appLanguage]['g100'] + ' ' + translations[appLanguage]['g70'],
      value: -2,
    },
    {
      key: translations[appLanguage]['g100'] + ' ' + translations[appLanguage]['g71'],
      text: translations[appLanguage]['g100'] + ' ' + translations[appLanguage]['g71'],
      value: -3,
    },
    {
      key: translations[appLanguage]['g100'] + ' ' + translations[appLanguage]['g67'],
      text: translations[appLanguage]['g100'] + ' ' + translations[appLanguage]['g67'],
      value: -4,
    },
    {
      key: translations[appLanguage]['g100'] + ' ' + translations[appLanguage]['g68'],
      text: translations[appLanguage]['g100'] + ' ' + translations[appLanguage]['g68'],
      value: -5,
    },
  ];
  return (
    <Dropdown
      disabled={disabled}
      style={{
        position: 'absolute',
        top,
        left,
        opacity: 0.9,
        minWidth: '90px',
      }}
      placeholder={translations[appLanguage]['g73']}
      value={bot?.building?.[placeIndex] >= 0 || bot?.building?.[placeIndex] < 0 ? bot?.building?.[placeIndex] : ''}
      selection
      options={bot?.botType !== 1 ? gameOptions : farmOptions}
      onChange={async (e, {value}) => {
        if (bot) {
          if (!bot?.building) bot.building = [];
          bot.building[placeIndex] = value;
          NM.info(translations[appLanguage]['g187'], '', 2000);
          await pushBot({_id: bot._id, building: bot.building});
          NM.success(translations[appLanguage]['g188'], '', 2000);
        }
        // if (onBuildingChange) onBuildingChange(value);
      }}
    />
  );
};

export default SelectBox;
