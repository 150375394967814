import React, {useEffect, useContext} from 'react';
import {Button, Icon} from 'semantic-ui-react';
import {translations} from 'resources';
import {LanguageContext} from 'context';
import styles from './pageHeader.module.css';

const PageHeader = (props) => {
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);

  useEffect(() => {
    if (props.pathName === '/' && localStorage.getItem('token')) {
      props.history.push('/panel');
    }
    if (
      props.pathName !== '/' &&
      props.pathName !== '/signin' &&
      props.pathName !== '/signup' &&
      !localStorage.getItem('token')
    ) {
      props.history.push('/signin');
    }
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.content_container}>
        <div className={styles.childContainer}>{props.children}</div>
      </div>
    </div>
  );
};

export default PageHeader;
