import React, {useContext} from 'react';
import {Dropdown} from 'semantic-ui-react';

import {translations} from 'resources';
import {LanguageContext, BotContext} from 'context';
import './botsBox.css';

const BotBox = ({botOptions, onBotSelect}) => {
  const {
    state: {appLanguage},
  } = useContext(LanguageContext);
  const {
    state: {bots, selectedIndex},
  } = useContext(BotContext);

  return (
    <Dropdown
      style={{
        minWidth: '100px',
        zIndex: 20,
      }}
      placeholder={translations[appLanguage]['g11']}
      selection
      options={botOptions}
      value={selectedIndex}
      onChange={(e, {value}) => {
        if (onBotSelect) onBotSelect(value);
      }}
    />
  );
};

export default BotBox;
