import {baseReducer} from 'context/shared';
import {ADD_MESSAGES, SET_MESSAGES, SET_PLAYERS, RESET_MESSAGES, SET_SELECTED_IGG_ID} from './keys';

export const initialState = {
  chats: {},
  players: [],
  selectedIggId: '',
  lastSendedMillis: 0,
};

export default baseReducer((state = initialState, {type, payload}) => {
  switch (type) {
    case SET_PLAYERS:
      return {...state, players: payload};
    case SET_MESSAGES:
      return {...state, chats: {...state.chats, [payload.iggId]: payload.data}};
    case SET_SELECTED_IGG_ID:
      return {...state, selectedIggId: payload};
    case ADD_MESSAGES:
      return {
        ...state,
        lastSendedMillis: Date.now(),
        chats: {...state.chats, [payload.iggId]: [...state.chats[payload.iggId], payload.data]},
      };
    case RESET_MESSAGES:
      return {...initialState};
    default:
      return state;
  }
});
