import React from 'react';

import SelectBox from '../../../../components/selectbox';
import backgroundImage from '../../../../../resources/images/fetihbotpanel.png';
import styles from './building-selection.module.css';
//234
//32

//0,628
//0,628
const fixer = 0.59;
export default function BuildingSelection(props) {
  return (
    <div className={styles.building_selection_container}>
      <img src={backgroundImage} className={styles.background_image} alt="backgroundImage" />
      <SelectBox {...props} placeIndex={27} top={32 * fixer} left={147} />
      <SelectBox {...props} placeIndex={26} top={167 * fixer} left={164} />
      <SelectBox {...props} placeIndex={29} top={236 * fixer} left={34} />
      <SelectBox {...props} placeIndex={25} top={267 * fixer} left={238} />
      <SelectBox {...props} placeIndex={28} top={319 * fixer} left={124} />
      <SelectBox {...props} placeIndex={19} top={452 * fixer} left={100} />
      <SelectBox {...props} placeIndex={15} top={444 * fixer} left={257} />
      <SelectBox {...props} placeIndex={18} top={548 * fixer} left={200} />
      <SelectBox {...props} placeIndex={16} top={548 * fixer} left={350} />
      <SelectBox {...props} placeIndex={4} top={497 * fixer} left={525} />
      <SelectBox {...props} placeIndex={24} top={626 * fixer} left={-3} />
      <SelectBox {...props} placeIndex={17} top={668 * fixer} left={270} />
      <SelectBox {...props} placeIndex={21} top={675 * fixer} left={104} />
      <SelectBox {...props} placeIndex={3} top={566 * fixer} left={606} />
      <SelectBox {...props} placeIndex={23} top={755 * fixer} left={11} />
      <SelectBox {...props} placeIndex={7} top={742 * fixer} left={536} />
      <SelectBox {...props} placeIndex={2} top={655 * fixer} left={695} />
      <SelectBox {...props} placeIndex={20} top={824 * fixer} left={116} />
      <SelectBox {...props} placeIndex={10} top={850 * fixer} left={281} />
      <SelectBox {...props} placeIndex={9} top={867 * fixer} left={514} />
      <SelectBox {...props} placeIndex={1} top={756 * fixer} left={786} />
      <SelectBox {...props} placeIndex={22} top={900 * fixer} left={8} />
      <SelectBox {...props} placeIndex={13} top={931 * fixer} left={199} />
      <SelectBox {...props} placeIndex={11} top={929 * fixer} left={361} />
      <SelectBox {...props} placeIndex={6} top={836 * fixer} left={629} />
      <SelectBox {...props} placeIndex={14} top={1021 * fixer} left={253} />
      <SelectBox {...props} placeIndex={12} top={1030 * fixer} left={405} />
      <SelectBox {...props} placeIndex={8} top={971 * fixer} left={593} />
      <SelectBox {...props} placeIndex={5} top={955 * fixer} left={730} />
      <SelectBox {...props} placeIndex={0} top={780 * fixer} left={900} />
    </div>
  );
}
